// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BrightCove_brightCoveContainer__2Hjo6 {\n    border: 1px solid #FFA850;\n}\n\n.BrightCove_brightCoveVideo__WB1lc {\n    width: 100%;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/BrightCoveICS/BrightCove.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".brightCoveContainer {\n    border: 1px solid #FFA850;\n}\n\n.brightCoveVideo {\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brightCoveContainer": "BrightCove_brightCoveContainer__2Hjo6",
	"brightCoveVideo": "BrightCove_brightCoveVideo__WB1lc"
};
export default ___CSS_LOADER_EXPORT___;
