import React from "react";

import classes from "./BrightCove.module.css";

const BrightCove = (props) => {
    return (
        <>
            <div className={classes.brightCoveContainer}>  
                <iframe className={classes.brightCoveIframe} src={props.embedCode} title="BrightCove" />
            </div>
        </>
    );
};

export default BrightCove;
