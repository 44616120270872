import React, { useEffect } from "react";
import classes from "./Riddle.module.css";
import { trackDigitalData } from "../../helpers/analytics";

const Riddle = (props) => {
    useEffect(() => {
        trackRiddleActions(props.keys);
    }, [props.keys]);

    return (
        <div className={classes.riddleContainer}>
            <iframe src={props.src} width="100%" height="100%"></iframe>
        </div>
    );
};

const trackRiddleActions = (keys) => {
    function onMessage(event) {
        console.log(event);
    }

    window.addEventListener('message', onMessage, true);
};

export default Riddle;