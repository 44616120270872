import { useState } from "react";

import Popup from "../Popup/Popup";
import { default as ToUrlBody } from "../ToUrl/Body/Body";
import { default as ToUrlAction } from "../ToUrl/Action/Action";
import { default as HcpBody } from "./Body/Body";
import { default as HcpFooter } from "./Footer/Footer";

import "./HonestyBox.css";

/**
 * RFC for honesty box
 * Healthcare speciality request
 * @param {*} props
 * @returns
 */
const HonestyBox = (props) => {
  const [iAmHealthcare, setIAmHealthcare] = useState(null);

  let header = "Welcome To Rapid Mood Screener";

  let body, footer;
  if (iAmHealthcare || iAmHealthcare === null) {
    body = <HcpBody />;
    footer = (
      <HcpFooter
        iAmHealthcare={iAmHealthcare}
        setIAmHealthcare={setIAmHealthcare}
      />
    );
  } else {
    header = (
      <div className="modal-title h4">
        You are now leaving Rapid Mood Screener
      </div>
    );
    body = <ToUrlBody />;
    footer = <ToUrlAction back={setIAmHealthcare} confirmTrackId="ats06048" backTrackId="ats06047"/>;
  }

  return (
    <Popup
      header={header}
      body={body}
      footer={footer}
      backdrop="static"
      keyboard={false}
      show={!props.isHcp}
      closeButton={false}
    />
  );
};

export default HonestyBox;
