import ExternalLink from "../../ExternalLink/ExternalLink";

import classes from "./Footer.module.css";

import privacyIcon from '../../../assets/images/privacyChoicesIcon.png';

const Footer = () => (
  <>
    <div className={`footer text-center ${classes.footerContainer}`}>
      <div className={classes.footerLink}>
        <ul>
          <li>
            <ExternalLink href="https://www.abbvie.com/our-company.html">
              About
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.abbvie.com/accessibility-statement.html">
              Accessibility Statement
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.abbvie.com/contactus.html">
              Contact Us
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.abbvie.com/termsofuse.html">
              Terms of Use
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html">
              Privacy Notice
            </ExternalLink>
          </li>

          <li>
            <span id="ot-sdk-btn">Cookies Settings</span>
          </li>
          <li className={classes.yourPrivacyChoices}>
            <ExternalLink href="https://abbviemetadata.my.site.com/AbbvieDSRM/">
              Your Privacy Choices
            </ExternalLink>
          </li>
          <img src={privacyIcon} alt="" className={classes.newPrivacyIcon} />
        </ul>
      </div>
      <div className={`text-center ${classes.copyrightText}`}>
        <p>
          <span>&copy;</span> {new Date().getFullYear()} AbbVie. All rights reserved.
        </p>
      </div>
    </div>
  </>
);

export default Footer;
