import { useState, useEffect } from "react";

import { trackDigitalData } from "../../../helpers/analytics";

import NewBanner from "../../../components/NewBanner/NewBanner";
import Quiz from "../../../components/Quiz/Quiz";
import References from "../../../components/References/References";
import classes from "./ScreeningPatientsWithBipolarDisorderInPractice.module.css";

import Riddle from "../../../components/Riddle/Riddle";

import img1 from "../../../assets/images/2024/patientCaseQuizzes/screeningPatientsWithBipolarDisorderInPractice/img1.png";

const ScreeningPatientsWithBipolarDisorderInPractice = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06245');
    }, []);
    return (
        <>
            <NewBanner title="Screening Patients With Bipolar Disorder in Practice" breadcrumbs={[{ title: "Clinical Experience", link: null }, { title: "Patient Case Quizzes", link: '/patient-case-quizzes', trackId: 'ats06260' }, { title: "Screening Patients With Bipolar Disorder in Practice", link: null }]} />
            <p className={classes.articleHook}>Does your patient have bipolar disorder, or another condition? Review this patient case to test your knowledge.</p>
            <img className={classes.articleImage1} src={img1} alt="Screening Patients With Bipolar Disorder in Practice" />
            <p className={classes.articleCaption}>For educational purposes only. Hypothetical patient case. Not an actual patient. Image is for illustrative use only.</p>
            <hr className={classes.articleHr} />
            <h2>Patient Profile</h2>
            <p>Mark is a 38-year-old man working as an IT Manager. He presents with depressed mood, feelings of worthlessness, irritability, and fatigue, loss of interest in daily activities, and difficulty concentrating. He has a Patient Health Questionnaire (PHQ)-9 score of 13. He expresses frustration over continued symptoms that have caused him to reduce hours at work despite being on an antidepressant medication.
            </p>
            <p>Mark's current depressive episode was diagnosed 3 months ago; at that time, his PHQ-9 score was 19. He was initiated on a selective serotonin reuptake inhibitor (SSRI) with an on-label dose for 6 weeks, then was then switched to a serotonin-norepinephrine reuptake inhibitor (SNRI) due to continued depressive symptoms after 8 weeks.
            </p>
            <p>Probing revealed a long history with depression, starting in college. Mark remembers responding well to an SNRI the first time he was treated for depression. Mark noted that he responded quickly and stopped the medication after 9 months. He also believes the medications he was given did not really work and that his “depression just got better on its own,” which is why he did not seek treatment for his second episode. Mark also reported that his subsequent depressive episodes have varied in length, but each bout of depression seemed to be worse than the last, and that financial difficulties have contributed to his symptoms as well. He mentioned drinking “a lot” of alcohol in college, but now only has “only a few beers” on weekends.
            </p>
            <p>Further conversation on Mark's medical and family history revealed that this current depressive episode is likely his third lifetime episode. He did not report mood swings or increased conﬁdence, feeling overly energetic, and needing less sleep. Mark noted feeling self-conscious with transient feelings of worthlessness and irritability. He has not exhibited risky behavior and noted that his ﬁnancial stress was due to working fewer hours. Additional questions revealed that Mark has had difﬁculty concentrating and experienced “fogginess” due to his symptoms. Mark also believes that his mother had depression.
            </p>
            <p>In reviewing medication history, Mark noted starting on an SNRI for his first depressive episode. He responded well to treatment and stopped taking his medication after 9 months; he did not take any medication during his second episode.</p>
            <hr className={classes.articleHr} />
            <p className={classes.articleQuizPrompt}>Based on the information provided in this case study, test your knowledge about screening Mark for bipolar disorder and other mental health conditions by taking the quiz below:</p>
            <Quiz id="quiz-screening-patients-with-bipolar-disorder-in-practice" embedCode="https://www.riddle.com/embed/a/LwpYJ5FK?lazyImages=false&staticHeight=false" keys={[
                {
                    question: 'ats06261',
                    answer: ['ats06278'],
                    next: 'ats06262',
                    explanation: 'ats06263'
                },
                {
                    question: 'ats06247',
                    answer: ['ats06264'],
                    next: 'ats06248',
                    explanation: 'ats06250'
                },
                {
                    question: 'ats06253',
                    answer: ['ats06252'],
                    next: 'ats06254',
                    explanation: 'ats06255'
                },
                {
                    question: 'ats0626',
                    answer: ['ats06245'],
                    next: 'ats06249',
                    explanation: 'ats06251'
                },
                {
                    question: 'ats06257',
                    answer: ['ats06256'],
                    next: 'ats06258',
                    explanation: 'ats06259'
                }
            ]}/>
            <p className={classes.articleCaption} style={{ fontStyle: "italic" }}>This content presented here is intended for educational purposes only and is intended for US healthcare professionals. Healthcare professionals should use independent medical judgment. All decisions regarding patient care must be handled by a healthcare professional and be made based on the unique needs of each patient.</p>
            <p className={classes.articleCaption}>ABBV-US-01771-MC, V1.0 | Approved 09/2024 | AbbVieMedical Affairs</p>
            <References>
                <li>
                    Fortinguerra S, Sorrenti V, Giusti P, et al. Pharmacogenomic characterization in bipolar spectrum disorders. Pharmaceutics. 2019;12(1):13. doi:10.3390/pharmaceutics12010013
                </li>
                <li>
                    Bobo WV. The diagnosis and management of bipolar I and II disorders: clinical practice update. Mayo Clin Proc. 2017;92(10):1532-1551. doi:10.1016/j.mayocp.2017.06.022
                </li>
                <li>
                    American Psychiatric Association. Diagnostic and Statistical Manual of Mental Disorders. 5th ed. American Psychiatric Publishing; 2013.
                </li>
                <li>
                    Oluboka OJ, Katzman MA, Habert J, et al. Functional recovery in major depressive disorder: providing early optimal treatment for the individual patient. Int J Neuropsychopharmacol. 2018;21(2):128-144. doi:10.1093/ijnp/pyx081
                </li>
            </References>
        </>
    );
};

export default ScreeningPatientsWithBipolarDisorderInPractice;
