// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntroducingTheRapidMoodScreener_articleCaption__378hP {\n    font-size: 18px;\n    font-weight: 350;\n    line-height: 34.2px;\n    letter-spacing: 0.01em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n}\n\n.IntroducingTheRapidMoodScreener_smallText__2Iypc {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 350;\n    line-height: 150%;\n    letter-spacing: 0.14px;\n}", "",{"version":3,"sources":["webpack://src/containers/Multimedia/IntroducingTheRapidMoodScreener/IntroducingTheRapidMoodScreener.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".articleCaption {\n    font-size: 18px;\n    font-weight: 350;\n    line-height: 34.2px;\n    letter-spacing: 0.01em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n}\n\n.smallText {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 350;\n    line-height: 150%;\n    letter-spacing: 0.14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleCaption": "IntroducingTheRapidMoodScreener_articleCaption__378hP",
	"smallText": "IntroducingTheRapidMoodScreener_smallText__2Iypc"
};
export default ___CSS_LOADER_EXPORT___;
