import { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Redirect } from "react-router";
import Paragraph from "../Paragraph/Paragraph";
import ExternalLink from "../ExternalLink/ExternalLink";

import { trackDigitalData } from "../../helpers/analytics";
import classes from "./TermsOfUse.module.css";

/**
 * RFC for RMS terms condition
 * @param {*} props
 * @returns <JSX />
 */
const TermsOfUse = (props) => {
  const [isAgree, setIsAgree] = useState(true);

  // function for terms validation
  const setTerms = () => {
    trackDigitalData('interaction', 'ats06323');
    if (document.getElementById("agree").checked) {
      props.setTouAgree(true);
    } else if (document.getElementById("disAgree").checked) {
      setIsAgree(false);
    }
  };

  if (!isAgree) {
    return <Redirect to="/bipolar-disorder" />;
  }

  return (
    <Row className="margin-to-banner main-container-padding">
      <Col>
        <Paragraph>
          <h2>Terms of Use</h2>
        </Paragraph>

        <Paragraph>
          <p className="mt-2">
            You acknowledge and agree that access to and use of the Rapid Mood
            Screener (RMS) is at your own risk. AbbVie makes no warranties or
            representations of any kind as to the accuracy, currency, or
            completeness of the information accessed and used through this
            website. Further, AbbVie makes no guarantees that using the RMS will
            result in your desired outcome. All content in this site is intended
            only for informational use by licensed U.S. healthcare
            professionals and is not offered as or intended to be medical advice
            for any particular patient. Because this questionnaire relies on
            patient self-reported information, only a licensed healthcare
            professional exercising independent clinical judgment can verify the
            results of the RMS to determine whether a more comprehensive
            diagnostic evaluation will be needed. The RMS is not a diagnostic
            tool. Subject to the terms above, you agree to AbbVie’s terms of use
            found at{" "}
            <ExternalLink
              href="https://www.abbvie.com/termsofuse.html"
              classes="anchor"
              trackId="ats06286"
              backTrackId="ats06287"
              confirmTrackId="ats06288"
            >
              <u>https://www.abbvie.com/termsofuse.html</u>
            </ExternalLink>.
            Use of and access to this site are subject to the terms of use and
            privacy policy at{" "}
            <ExternalLink
              href="https://www.abbvie.com/privacy.html"
              classes="anchor"
              trackId="ats06289"
              backTrackId="ats06321"
              confirmTrackId="ats06322"
            >
              <u>https://www.abbvie.com/privacy.html</u>
            </ExternalLink>
            .
          </p>
        </Paragraph>

        <Paragraph>
          <Form className="mt-5 mb-5">
            <Row className={classes.radioContainer}>
              <Col lg={6} md={6} sm={6}>
                <Form.Check
                  className="float-right"
                  custom
                  type="radio"
                  label="Agree"
                  name="terms"
                  id="agree"
                  onClick={() => trackDigitalData('interaction', 'ats06290')}
                />
              </Col>
              <Col lg={6} md={6} sm={6}>
                <Form.Check
                  className="float-left"
                  custom
                  type="radio"
                  label="Disagree"
                  name="terms"
                  id="disAgree"
                  onClick={() => trackDigitalData('interaction', 'ats06291')}
                />
              </Col>
            </Row>

            <Row className="text-center mt-4 mb-3 ml-0">
              <Col lg={12} sm={12}>
                <Button
                  variant="primary"
                  onClick={setTerms}
                  className={`${classes.btnSubmit} active`}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default TermsOfUse;
