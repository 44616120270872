// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BrightCove_brightCoveContainer__2I9Wh {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.BrightCove_brightCoveIframe__o227U {\n    width: 100%;\n    height: 530px;\n    border: 1px solid #FFA850;\n}\n", "",{"version":3,"sources":["webpack://src/components/BrightCove/BrightCove.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".brightCoveContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.brightCoveIframe {\n    width: 100%;\n    height: 530px;\n    border: 1px solid #FFA850;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brightCoveContainer": "BrightCove_brightCoveContainer__2I9Wh",
	"brightCoveIframe": "BrightCove_brightCoveIframe__o227U"
};
export default ___CSS_LOADER_EXPORT___;
