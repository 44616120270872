import { useState, useEffect } from "react";

import NewBanner from "../../../components/NewBanner/NewBanner";

import classes from "./UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis.module.css";

import References from "../../../components/References/References";

import img1 from "../../../assets/images/2024/clinicalArticleSummaries/unrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis/img1.png";

import { trackDigitalData } from "../../../helpers/analytics";

const UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06317');
    }, []);
    return (
        <>
            <NewBanner title="Clinical Article Summaries" breadcrumbs={[{ title: "Clinical Experience", link: null }, { title: "Clinical Article Summaries", link: '/clinical-article-summaries', trackId: 'ats06318' }, { title: "Unrecognized Bipolar Disorder in Patients With Depression Managed in Primary Care: A Systematic Review and Meta-Analysis", link: null }]} />
            <h2 className={classes.articleTitle}>Unrecognized Bipolar Disorder in Patients With Depression Managed in Primary Care: A Systematic Review and Meta-Analysis</h2>
            <p>Daveney et al explore the characteristics of patients with mixed symptoms, as compared to those without mixed symptoms, in both bipolar disorder and major depressive disorder.</p>
            <img className={classes.articleImage} src={img1} alt="Unrecognized Bipolar Disorder in Patients With Depression Managed in Primary Care: A Systematic Review and Meta-Analysis" />
            <p></p>

            <h3>Introduction:</h3>
            <p>
                Most patients with depression are treated in primary care. There is evidence that many patients who seek mental healthcare from a primary care provider have bipolar disorder but may be misdiagnosed with depression. This has serious treatment implications because antidepressants can trigger a hypomanic or manic episode and can increase the risk of suicide in patients with bipolar disorder. This study was conducted because the researchers found that there were no recent collated data for bipolar disorder misdiagnoses.
                <br />
                <br />
                Daveney et al explore the characteristics of patients with mixed symptoms, as compared to those without mixed symptoms, in both bipolar disorder and major depressive disorder. It is likely that more than 15% of patients being treated for depression in a primary care setting have bipolar disorder. Appropriate training and diagnostic tools can help decrease misdiagnoses and improve appropriate treatment.
            </p>
            <h3>Methods:</h3>
            <p>
                The goal of the study was to determine the percentage of primary care patients with diagnosed depression who had unrecognized bipolar disorder. To do this, the researchers conducted a systematic literature review of studies on undiagnosed or misdiagnosed bipolar disorder among patients being treated for depression. The search was performed on January 10, 2019, and there was no age limit on the articles included. In addition, the researchers conducted a sensitivity analysis to assess potential bias in the study results. They also analyzed whether the way that the diagnoses were made—through a diagnostic interview or self-reported questionnaire—impacted the prevalence rates of misdiagnosis.
            </p>
            <h3>Results:</h3>
            <p>
                Ten studies met the inclusion criteria, with 3803 total participants. The researchers found that up to 17 of 100 patients diagnosed with depression in primary care may have had unrecognized bipolar disorder (95% confidence interval (CI) = 12-22). The prevalence of undiagnosed bipolar disorder in studies that used questionnaires as assessment tools tended to be higher than those that used diagnostic interviews, though this difference was not statistically significant.
            </p>
            <h3>Discussion:</h3>
            <p>
                Many patients with depressive symptoms seek help from primary care providers and are prescribed antidepressants. This treatment choice can potentially result in negative consequences if the patient has been misdiagnosed with depression but has bipolar disorder. Understanding the potential rate of misdiagnosis is important for improvements in diagnoses and treatment. The implications of this study are limited as only 10 studies met the inclusion criteria and there was a large degree of variation across the studies (I2 = 95%, 95% CI = 69-100). Because of the complexity of bipolar disorder, it is also likely that the estimates of bipolar misdiagnosis were conservative in some studies.
                <br />
                <br />
                While the rate of undiagnosed bipolar disorder found by this meta-analysis appears high, the results must be taken with caution because of the small number of studies included. More studies are needed on the misdiagnosis of different subtypes of bipolar disorder, as well as examining the diagnosis rates across different demographics. These findings highlight the need for appropriate mental health diagnostic training in primary care settings and utilization of effective tools to assess bipolar disorder.
            </p>

            <hr />

            <p className={classes.disclaimer}>
                This summary was prepared independently of the study's authors. This content presented here is intended for educational purposes only and is intended for US healthcare professionals. Healthcare professionals should use independent medical judgment. All decisions regarding patient care must be handled by a healthcare professional and be made based on the unique needs of each patient.
            </p>
            <p className={classes.stamp}>
                ABBV-US-01773-MC | Approved 09/2024 | AbbVie Medical Affairs
            </p>

            <References>
                <li>
                    Daveney J, Panagioti M, Waheed W, Esmail A. Unrecognized bipolar disorder in patients with depression managed in primary care: A systematic review and meta-analysis. <span style={{fontStyle: "italic"}}>Gen Hosp Psychiatry</span>. 2019;58:71-76. doi:10.1016/j.genhosppsych.2019.03.006
                </li>
            </References>
        </>
    );
};

export default UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis;
