import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styles from "./Navigator.module.css";

import { useState } from "react";
import Popup from "../../Popup/Popup";
import Body from "../../ToUrl/Body/Body";
import Action from "../../ToUrl/Action/Action";

import { trackDigitalData } from "../../../helpers/analytics";

const Navigator = (props) => {

  const [showPopup, setShowPopup] = useState(false);

  const redirectTo = (e) => {
    e.preventDefault();
    setShowPopup(true);
    trackDigitalData('interaction', 'ats06069', true);
  };

  return (
    <Nav onClick={props.clicked}>
      <NavDropdown className={styles.dropdown} title="About RMS" id="about-dropdown">
        <NavDropdown.Item as={NavLink} to="/rms" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06304', true)}>
          Access the Tool
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="/clinician-guide" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06303', true)}>
          Clinician Guide
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="/development" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06305', true)}>
          Development and Validation
        </NavDropdown.Item>
        {/*
        <NavDropdown.Item as={NavLink} to="/validation" className={styles.dropdownItem}>
          Publications
        </NavDropdown.Item>
        */}
      </NavDropdown>

      <NavDropdown className={styles.dropdown} title="Clinical Experience" id="clinical-dropdown">
        <NavDropdown.Item as={NavLink} to="/case-studies/case-study-1" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06062', true)}>
          Interactive Case Studies
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="/patient-case-quizzes" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06064', true)}>
          Patient Case Quizzes
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="/multimedia" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06065', true)}>
          Multimedia
        </NavDropdown.Item>
      </NavDropdown>

      <NavDropdown className={styles.dropdown} title="Bipolar I Disorder Resources" id="bipolar-dropdown">
        <NavDropdown.Item as={NavLink} to="/bipolar-disorder" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06063', true)}>
          Bipolar I Disorder Disease State Education
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="/quick-guides-for-providers" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06059', true)}>
          Quick Guides for Providers
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="/clinical-article-summaries" className={styles.dropdownItem} onClick={() => trackDigitalData('interaction', 'ats06066', true)}>
          Clinical Article Summaries
        </NavDropdown.Item>
      </NavDropdown>

      {showPopup && (
        <Popup
          header="You are now leaving Rapid Mood Screener"
          body={<Body toUrl="https://www.contactmymsl.abbvieusmedicalaffairs.com/" />}
          footer={
            <Action
              toUrl="https://www.contactmymsl.abbvieusmedicalaffairs.com/"
              back={setShowPopup}
              backTrackId="ats06067"
              confirmTrackId="ats06068"
            />
          }
          closeButton={false}
          backdrop={true}
          keyboard={false}
          show={showPopup}
          closeModal={setShowPopup}
        />
      )}

      <Nav.Link
        href="https://www.contactmymsl.abbvieusmedicalaffairs.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.mslLink}
        onClick={redirectTo}
      >
        CONTACT MSL
      </Nav.Link>
    </Nav>
  );
};

export default Navigator;