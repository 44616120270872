import { Row, Col } from "react-bootstrap";
import Paragraph from "../../../components/Paragraph/Paragraph";
import ExternalLink from "../../../components/ExternalLink/ExternalLink";

const RMSGuide = (props) => {
  return (
    <Row className="margin-to-banner main-container-padding">
      <Col>
        <Paragraph>
          <p className="mt-2">
            Over half of patients with bipolar disorder who had been previously
            misdiagnosed had a delay of 5 years or more between seeking
            treatment and the correct diagnosis, and over one third of patients
            had a delay of 10 years or more.<sup>1</sup> In a survey of 600 bipolar patients, approximately 70% of patients with bipolar disorder reported being initially misdiagnosed, with major depressive disorder (MDD) the most common misdiagnosis.<sup>1</sup>
          </p>
        </Paragraph>
        <Paragraph>
          <p>
            The Rapid Mood Screener (RMS) was developed by a team of
            multidisciplinary experts (primary care clinician, psychiatry nurse
            practitioner, psychiatrists, pharmacist, behavioral therapists,
            psychometricians and health economists) to provide a pragmatic
            approach to address the need for timely and accurate evaluation of
            bipolar disorder. The screener was validated in a study of patients
            with bipolar I disorder and MDD<sup>2</sup> and the publication can
            be accessed from the following{" "}
            <ExternalLink
              href="https://www.tandfonline.com/doi/full/10.1080/03007995.2020.1860358"
              classes="anchor"
              trackId="ats06313"
              backTrackId="ats06309"
              confirmTrackId="ats06310"
            >
              <u>journal website</u>
            </ExternalLink>
            .
          </p>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default RMSGuide;
