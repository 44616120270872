import React, { useEffect } from "react";
import classes from "./BrightCove.module.css";
import { trackDigitalData } from "../../helpers/analytics";

const BrightCove = (props) => {
    // Move uniquePlayerId definition here, outside useEffect but inside the component function
    const uniquePlayerId = `player_${Math.random().toString(36).substr(2, 9)}`;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//players.brightcove.net/1029485116001/default_default/index.min.js";
        script.async = true;

        script.onload = () => {
            if (window.videojs) {
                trackVideoActions(props.keys, uniquePlayerId); // Use uniquePlayerId here
            }
        };

        document.body.appendChild(script);

        return () => {
            const player = window.videojs && window.videojs.getPlayer(uniquePlayerId);
            if (player) {
                player.dispose();
            }
            document.body.removeChild(script);
        };
    }, [props.keys, uniquePlayerId]); // Add uniquePlayerId as a dependency

    return (
        <div className={classes.brightCoveContainer} style={{
            width: props.width || '452px',
            height: props.height || '255px'
        }}>
            <video-js
                data-account="1029485116001"
                data-player="default"
                data-embed="default"
                controls
                data-video-id={props.videoId}
                data-playlist-id=""
                data-application-id=""
                className={classes.brightCoveVideo}
                id={uniquePlayerId} // Now uniquePlayerId is accessible here
                style={{ width: "100%", height: "100%" }}
            >
            </video-js>
        </div>
    );
};

const trackVideoActions = (keys, playerId) => {
    window.videojs(playerId).ready(function () {
        const player = this;
        let milestonesReached = [];
        let videoEnded = false;

        player.on('play', function () {
            const currentTime = player.currentTime();

            if (currentTime === 0) {
                if (videoEnded) {
                    trackDigitalData('Interaction', keys.replay);
                    videoEnded = false;
                } else {
                    trackDigitalData('Interaction', keys.start);
                }
            } else {
                trackDigitalData('Interaction', keys.resume);
            }
        });

        player.on('pause', function () {
            const duration = player.duration();
            const currentTime = player.currentTime();
            if (duration - currentTime > 2) {
                trackDigitalData('Interaction', keys.pause);
            }
        });

        player.on('timeupdate', function () {
            const duration = player.duration();
            const currentTime = player.currentTime();
            const percentComplete = (currentTime / duration) * 100;

            const milestoneEvents = [
                { percent: 25, name: '25%' },
                { percent: 50, name: '50%' },
                { percent: 75, name: '75%' },
                { percent: 100, name: 'End' }
            ];

            milestoneEvents.forEach(event => {
                if (percentComplete >= event.percent && !milestonesReached.includes(event.percent)) {
                    milestonesReached.push(event.percent);
                    trackDigitalData('Interaction', keys[event.name]);
                }
                if (percentComplete === 100) {
                    videoEnded = true;
                }
            });
        });
    });
};

export default BrightCove;