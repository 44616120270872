// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PredictiveTable_tableContainer__11LYq th, .PredictiveTable_tableContainer__11LYq td {\n    text-align: center;\n    padding: 3px;\n}\n\n.PredictiveTable_tableContainer__11LYq td {\n    border-left: 1px solid #fff;\n}\n\n.PredictiveTable_tableContainer__11LYq tbody:last-child { \n    border-right: 1px solid #d3cccc;\n}\n\n.PredictiveTable_tableContainer__11LYq tbody tr:nth-child(odd) {\n    background-color: #fdcd9d;\n}\n\n.PredictiveTable_tableContainer__11LYq tbody tr:nth-child(even) {\n    background-color: #FFF4E8;\n}\n\n.PredictiveTable_tableText__2WQno {\n    font-size: 12px;\n    margin-left: 0px;\n    line-height: 1.8;\n    display: inline;\n}\n\n@media only screen and (max-width: 768px) {\n    .PredictiveTable_tableContainer__11LYq {\n        display: block;\n        margin-left: -11px!important;\n        margin-bottom: -12px;\n    }\n}", "",{"version":3,"sources":["webpack://src/containers/Development/PredicitveTable/PredictiveTable.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,cAAc;QACd,4BAA4B;QAC5B,oBAAoB;IACxB;AACJ","sourcesContent":[".tableContainer th, .tableContainer td {\n    text-align: center;\n    padding: 3px;\n}\n\n.tableContainer td {\n    border-left: 1px solid #fff;\n}\n\n.tableContainer tbody:last-child { \n    border-right: 1px solid #d3cccc;\n}\n\n.tableContainer tbody tr:nth-child(odd) {\n    background-color: #fdcd9d;\n}\n\n.tableContainer tbody tr:nth-child(even) {\n    background-color: #FFF4E8;\n}\n\n.tableText {\n    font-size: 12px;\n    margin-left: 0px;\n    line-height: 1.8;\n    display: inline;\n}\n\n@media only screen and (max-width: 768px) {\n    .tableContainer {\n        display: block;\n        margin-left: -11px!important;\n        margin-bottom: -12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "PredictiveTable_tableContainer__11LYq",
	"tableText": "PredictiveTable_tableText__2WQno"
};
export default ___CSS_LOADER_EXPORT___;
