import { useEffect, useState } from "react";
import { Row, Col, InputGroup } from "react-bootstrap";

import NewBanner from "../../components/NewBanner/NewBanner";
import Question from "../../components/Question/Question";
import TermsOfUse from "../../components/TermsOfUse/TermsOfUse";
import RMSActions from "../../components/RMSActions/RMSActions";
import Paragraph from "../../components/Paragraph/Paragraph";
import Results from "../../components/RmsResults/RmsResults";

import banner from "../../assets/images/rms_banner.jpg";
import mobileBanner from "../../assets/images/rms_mobile.jpg";

import { RMS_QUESTION } from "../../config/config";

import classes from "./RMS.module.css";

const RMS = (props) => {
  const [expandAll, setExpandAll] = useState(false);
  const [touAgree, setTouAgree] = useState(false);
  const [questionConfig, setQuestionConfig] = useState(RMS_QUESTION);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showResult, setShowResults] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);

  useEffect(() => {
    document.title = "RMS | Rapid Mood Screener";
  });

  const toggleExpandAll = (flag) => {
    setExpandAll(flag);
  };

  const resetForm = () => {
    setQuestionConfig(RMS_QUESTION);
    setIsFormSubmitted(false);
    setIsFormValid(false);
    setShowResults(false);
    setIsFormTouched(false);
  };

  const submitHandler = () => {
    setIsFormSubmitted(true);
    let formIsValid = true;
    questionConfig.forEach((value, index) => {
      if (value.answer === null) {
        formIsValid = value.answer !== null && formIsValid;
      }
    });
    setIsFormValid(formIsValid);
    if (formIsValid) setShowResults(true);
  };

  let container;

  if (touAgree) {
    container = (
      <Row className="margin-to-banner main-container-padding">
        <Col>
          <Row className={`${classes.rmsHeading}`}>
            <Col>
              <h1>Rapid Mood Screener (RMS)</h1>
            </Col>
          </Row>
          <Paragraph rowClasses=" mt-3">
            <p className={`${classes.rmsInfo}`}>
              Do you have patients who are exhibiting depressive symptoms and a
              history that may indicate mania?
              <br />
              This patient questionnaire can help you identify when additional
              assessment for bipolar I disorder is warranted.
            </p>
          </Paragraph>

          <Paragraph>
            <p className={`${classes.questionInfo}`}>
              Dear healthcare professional, the information on this website and
              from the questionnaire are not intended to replace your
              professional/clinical judgment. The content is designed to provide
              educational information and not intended to define a standard of
              care or exclusive course of treatment. In using the information on
              this website, you are responsible for considering the patient’s
              particular situation in evaluating the appropriateness of the
              information. The Rapid Mood Screener is not a diagnostic tool.
            </p>
          </Paragraph>
          {showResult && (
            <Row className={`mt-3 ${classes.patientInfo}`}>
              <Col lg={8} md={8}>
                <InputGroup className={`mb-0`}>
                  <label>Patient Name/Initials:</label>
                  <input
                    className="form-control"
                    type="text"
                    id="patientName"
                  />
                </InputGroup>
                <hr></hr>
              </Col>
              <Col lg={4} md={4} className={`${classes.inputMargin}`}>
                <InputGroup className="mb-0">
                  <label>Date:</label>
                  <input type="text" id="date" />
                </InputGroup>
                <hr></hr>
              </Col>
            </Row>
          )}

          <Paragraph>
            <p className={`${classes.questionInfo}`}></p>
          </Paragraph>

          <Paragraph>
            <p className={`${classes.questionInfo}`}>
              Please select one response for each question.
            </p>
          </Paragraph>

          <Row className="mx-0">
            <Col>
              {questionConfig.map((q, i) => {
                return (
                  <Question
                    key={`question-${q.id}`}
                    index={i}
                    id={q.id}
                    question={q.question}
                    answer={q.answer}
                    open={q.open}
                    config={questionConfig}
                    expandAll={expandAll}
                    isFormSubmitted={isFormSubmitted}
                    setConfig={setQuestionConfig}
                    setIsFormTouched={setIsFormTouched}
                  />
                );
              })}
            </Col>
          </Row>
          {!isFormValid && isFormSubmitted && (
            <Row className="mx-0 mt-4 mb-4">
              <Col className={`${classes.box} ${classes.arrowTop}`}>
                <p className={`text-center ${classes.textAlertOne}`}>
                  Please answer all the questions.
                </p>
                <p className={`text-center ${classes.textAlertTwo}`}>
                  *Red indicates the fields that are incomplete.
                </p>
              </Col>
            </Row>
          )}

          <Row className={`mx-0 mt-4 ${classes.resultsBlock}`}>
            <Col className={`${classes.box} ${classes.arrowTop} p-4`}>
              <p className={`text-left ${classes.textAlertPrint}`}>
                “YES” responses to 4 or more of the 6 items is considered a
                positive screen providing high confidence for bipolar I
                disorder, with an estimated 88% sensitivity, 80% specificity,
                and 84% accuracy
              </p>
              <p className={`text-left ${classes.textAlertPrint}`}>
                Sensitivity = percentage of patients who have bipolar I disorder
                that are correctly identified as positive
                <br />
                Specificity = percentage of patients who do not have bipolar I
                disorder and who are correctly identified as negative
                <br />
                Accuracy = percentage of patients correctly predicted as bipolar
                I disorder or not
              </p>
            </Col>
          </Row>

          <RMSActions
            submitted={submitHandler}
            resetForm={resetForm}
            isFormTouched={isFormTouched}
          />
          <Results
            toggleExpandAll={toggleExpandAll}
            showResults={showResult}
            setShowResults={setShowResults}
          />
        </Col>
      </Row>
    );
  } else {
    container = <TermsOfUse setTouAgree={setTouAgree} />;
  }

  return (
    <Row>
      <Col>
        <NewBanner title="Access the Tool" breadcrumbs={[{}]} />
        {container}
      </Col>
    </Row>
  );
};

export default RMS;
