import { Row, Col, Button, Container } from "react-bootstrap";

import Popup from "../Popup/Popup";
import { isIos } from "../../helpers/isIos";

import { MOBILE_VIEW } from "../../config/config";
import pdf from "../../assets/pdfs/Rapid_Mood_Screener_Questionaire-2.pdf";

import "./RmsResults.css";

import { trackDigitalData } from "../../helpers/analytics";

/**
 * RFC for RMS results
 * @param {*} props
 * @returns <JSX />
 */
const Results = (props) => {
  const printPage = () => {
    trackDigitalData('interaction', 'ats06299');
    if (window.innerWidth <= MOBILE_VIEW) {
      props.toggleExpandAll(true);
      setTimeout(() => {
        window.print();
        setTimeout(() => {
          props.toggleExpandAll(false);
        }, 700);
      }, 700);
    } else {
      window.print();
    }
  };

  const downloadPdf = (e) => {
    trackDigitalData('interaction', 'ats06300');
    e.preventDefault();
    let anchor = document.createElement("a");
    anchor.setAttribute("target", "_blank");

    anchor.setAttribute("href", pdf);
    if (isIos()) {
      anchor.setAttribute("target", "_blank");
    } else {
      anchor.setAttribute("download", "Clinician_Guide.pdf");
    }

    anchor.click();
  };

  const body = (
    <Container className="mt-0 ml-2">
      <Row>
        <Col className="p-3 rmsResultData">
          <p>
            “YES” responses to 4 or more of the 6 items is considered a positive
            screen providing high confidence for bipolar I disorder, with an
            estimated 88% sensitivity, 80% specificity, and 84% accuracy
          </p>
          <p>
            Sensitivity = percentage of patients who have bipolar I disorder
            that are correctly identified as positive <br />
            Specificity = percentage of patients who do not have bipolar I
            disorder and who are correctly identified as negative <br />
            Accuracy = percentage of patients correctly predicted as bipolar I
            disorder or not
          </p>
        </Col>
      </Row>
    </Container>
  );

  const footer = (
    <Container className="mb-0 mt-0">
      <Row className="float-right">
        <Col>
          <Button
            variant=""
            className="btn active buttonPrint"
            onClick={printPage}
          >
            Print RMS Questionnaire
          </Button>
          <br />
          <a
            className="rmsClinicianPdfBtn"
            style={{ color: "white" }}
            onClick={downloadPdf}
            href="#"
          >
            <small>Download Clinician Guide</small>
          </a>
        </Col>
      </Row>
    </Container>
  );

  return (
    <Popup
      header=""
      body={body}
      footer={footer}
      show={props.showResults}
      keyboard={false}
      backdrop={true}
      closeModal={props.setShowResults}
      closeButton={true}
    />
  );
};

export default Results;
