import { Row, Col } from "react-bootstrap";
import Paragraph from "../../../components/Paragraph/Paragraph";
import ExternalLink from "../../../components/ExternalLink/ExternalLink";

const Details = () => (
  <Row>
    <Col>
      <Paragraph>
        <p className="mt-2">
          The RMS validation study publication can be accessed from the
          following{" "}
          <ExternalLink
            href="https://www.tandfonline.com/doi/full/10.1080/03007995.2020.1860358"
            classes="anchor"
          >
            <u>journal website</u>
          </ExternalLink>
          .<sup>1</sup>
        </p>
      </Paragraph>

      <Paragraph>
        <p className="mb-3">
          The RMS was validated in an observational study that included 139
          participants with a confirmed diagnosis of bipolar I disorder or MDD
          <b> (Phase 3)</b>.<sup>1</sup> Participants completed a draft bipolar
          I disorder screening tool and additional study measures, including the
          15-item Mood Disorder Questionnaire (MDQ), a widely known tool that
          screens for bipolar I and II disorder.<sup>2</sup>
        </p>
      </Paragraph>
    </Col>
  </Row>
);

export default Details;