// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About_videoImage__gi0GV {\n  width: 100%;\n}\n\n.About_accessToolButton__rQ--e {\n  background-color: #071D49;\n}\n\n.About_aboutTitle__2xHRF {\n  font-size: 42px;\n  font-weight: 700;\n  line-height: 63.25px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  font-family: 'NeueHaasUnicaW02-Regular';\n  color: #071D49;\n}\n\n.About_aboutDescription__3lpqL {\n  text-align: center;\n  font-family: \"Neue Haas Unica W1G\";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 160%;\n  letter-spacing: 0.24px;\n  font-family: 'NeueHaasUnicaW02-Light';\n  width: 988px;\n  color: #071D49;\n}\n\n.About_stamp__3y80V {\n  font-size: 14px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/containers/About/About.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,sBAAsB;EACtB,kBAAkB;EAClB,kCAAkC;EAClC,8BAA8B;EAC9B,uCAAuC;EACvC,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,qCAAqC;EACrC,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".videoImage {\n  width: 100%;\n}\n\n.accessToolButton {\n  background-color: #071D49;\n}\n\n.aboutTitle {\n  font-size: 42px;\n  font-weight: 700;\n  line-height: 63.25px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  font-family: 'NeueHaasUnicaW02-Regular';\n  color: #071D49;\n}\n\n.aboutDescription {\n  text-align: center;\n  font-family: \"Neue Haas Unica W1G\";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 160%;\n  letter-spacing: 0.24px;\n  font-family: 'NeueHaasUnicaW02-Light';\n  width: 988px;\n  color: #071D49;\n}\n\n.stamp {\n  font-size: 14px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoImage": "About_videoImage__gi0GV",
	"accessToolButton": "About_accessToolButton__rQ--e",
	"aboutTitle": "About_aboutTitle__2xHRF",
	"aboutDescription": "About_aboutDescription__3lpqL",
	"stamp": "About_stamp__3y80V"
};
export default ___CSS_LOADER_EXPORT___;
