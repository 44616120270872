import { useState, useEffect } from "react";

import NewBanner from "../../../components/NewBanner/NewBanner";

import classes from "./CharacteristicsOfPatientsWithEarlyBipolarDisorder.module.css";

import References from "../../../components/References/References";

import { trackDigitalData } from "../../../helpers/analytics";
import img1 from "../../../assets/images/2024/clinicalArticleSummaries/characteristicsOfPatientsWithEarlyBipolarDisorder/listingImage0.jpg";

const CharacteristicsOfPatientsWithEarlyBipolarDisorder = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06284');
    }, []);
    return (
        <>
            <NewBanner title="Clinical Article Summaries" breadcrumbs={[{ title: "Clinical Experience", link: null }, { title: "Clinical Article Summaries", link: '/clinical-article-summaries', trackId: 'ats06285' }, { title: "Characteristics of Patients With Early Bipolar Disorder", link: null }]} />
            <h2 className={classes.articleTitle}>Characteristics of Patients With Early Bipolar Disorder</h2>
            <p>O Donovan et al identify characteristics of patients who experience depression before bipolar disorder.</p>
            <img className={classes.articleImage} src={img1} alt="Characteristics of Patients With Early Bipolar Disorder" />
            <p></p>

            <h3>Introduction:</h3>
            <p>
                Patients experiencing major depressive episodes may have unrecognized first episode or early-stage bipolar disorder. Many cases of bipolar disorder begin with symptoms of depression, and (hypo)mania may not appear until years later. Researchers also noted some forms of depression may be variants of the bipolar genetic spectrum.
                <br />
                <br />
                O Donovan et al identify characteristics of patients who experience depression before bipolar disorder. This study finds there are clinical characteristics that can be associated with distinguishing depression that is likely to be early bipolar disorder or major depressive disorder (MDD). These characteristics may have implications for diagnosis and treatment management.
            </p>
            <h3>Methods:</h3>
            <p>
                Researchers aimed to identify the clinical characteristics of patients presenting with an episode of depression who went on to develop mania or hypomania. To do this, they conducted a literature review for the best identification of depressive episodes in probable early stages of bipolar disorder. Additionally, they suggested an approach to management of depressive episodes that addresses the uncertainty of the appropriate diagnosis.
            </p>
            <h3>Results:</h3>
            <p>
                Researchers found existing literature supports distinguishing between depressive symptoms that are likely to be indicative of an early episode of bipolar depression rather than MDD.
                <br />
                <br />
                While there are currently no criteria or biomarkers for depression preceding first episode (hypo)mania, clinical features that can help identify pre-bipolar depression are family history, course of illness, and symptoms.
                <br />
                <br />
                Some studies included in the review suggest that there's a difference in the manifestation of early depression depending on family history. The Pittsburgh high-risk study cited found depression that was more severe with more prominent atypical symptoms, including hypersomnia, fatigue, psychomotor retardation and sense of hopelessness, predicted higher risk for bipolar disorder.
                <br />
                <br />
                Long-term data highlighting conversion rates to bipolar I or bipolar II disorder among patients with MDD found new onsets of mania and hypomania ranged between 5% and 12.2% across a number of studies. The strongest predictors of bipolar conversion were psychosis or psychotic depression, prior diagnosis of psychosis, subthreshold hypomanic symptoms during the depressive episodes, inpatient treatment, parental diagnosis of bipolar disorder, and early onset of depression.
                <br />
                <br />
                Researchers noted some people with bipolar depression may never develop a manic episode. In these patients, family history of bipolar disorder may indicate their diagnosis of unipolar depression is in fact, bipolar depression, though more research is needed.
                <br />
                <br />
                The most consistent signs that can point to a bipolar diagnosis included:
                <ul>
                    <li>an accurate identification of depression with early onset</li>
                    <li>recurrent course with multiple episodes</li>
                    <li>presence of subthreshold hypomanic and/or mixed symptoms</li>
                    <li>family history of bipolar disorder</li>
                    <li>an adverse response to antidepressants</li>
                </ul>
            </p>
            <h3>Discussion:</h3>
            <p>
                While it is now largely accepted that bipolar and unipolar depression are treated differently, there are limited practical guidelines or specific treatment recommendations at this time for management of patients with potential bipolar depression or unipolar depression with a family history of bipolar disorder. This is significant considering treatment with antidepressants can be harmful in patients with unrecognized bipolar depression by triggering (hypo)mania, mixed symptoms, and worsening of depression with a potential increased risk of suicide. Patients with pre-bipolar depression may be particularly at-risk.
                <br />
                <br />
                Authors suggest improved brain imaging technology and future genetic research could make early identification and accurate diagnosis of bipolar disorder easier through informative markers and polygenic risks scores.
            </p>

            <hr />

            <p className={classes.disclaimer}>
                This summary was prepared independently of the study's authors. The content presented here is intended for educational purposes only and is intended for US healthcare professionals. Healthcare professionals should use independent medical judgment. All decisions regarding patient care must be handled by a healthcare professional and be made based on the unique needs of each patient.
            </p>

            <References>
                <li>
                    O'Donovan C, Alda M. Depression preceding diagnosis of bipolar disorder. <span style={{fontStyle: "italic"}}>Front Psychiatry</span>. 2020;11:500. doi:10.3389/fpsyt.2020.00500
                </li>
            </References>
        </>
    );
};

export default CharacteristicsOfPatientsWithEarlyBipolarDisorder;
