// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CharacteristicsOfPatientsWithEarlyBipolarDisorder_articleImage__2UTcP {\n    width: 100%;\n    height: auto;\n}\n\n.CharacteristicsOfPatientsWithEarlyBipolarDisorder_disclaimer__1JYYU {\n    color: #000;\n    font-size: 14px;\n    font-style: italic;\n    font-weight: 350;\n    line-height: 150%;\n    letter-spacing: 0.14px;\n}\n\n.CharacteristicsOfPatientsWithEarlyBipolarDisorder_stamp__35YR2 {\n    color: #000;\n    font-size: 14px;\n    font-weight: 350;\n    line-height: 150%;\n    letter-spacing: 0.14px;\n}\n\nhr {\n    border-top: 1px solid #FF8307;\n}\n\n.CharacteristicsOfPatientsWithEarlyBipolarDisorder_articleTitle__1DB3c {\n    margin-bottom: 40px;\n}\n\n.CharacteristicsOfPatientsWithEarlyBipolarDisorder_articleImage__2UTcP {\n    margin-top: 40px;\n    margin-bottom: 40px;\n}", "",{"version":3,"sources":["webpack://src/containers/ClinicalArticleSummaries/CharacteristicsOfPatientsWithEarlyBipolarDisorder/CharacteristicsOfPatientsWithEarlyBipolarDisorder.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".articleImage {\n    width: 100%;\n    height: auto;\n}\n\n.disclaimer {\n    color: #000;\n    font-size: 14px;\n    font-style: italic;\n    font-weight: 350;\n    line-height: 150%;\n    letter-spacing: 0.14px;\n}\n\n.stamp {\n    color: #000;\n    font-size: 14px;\n    font-weight: 350;\n    line-height: 150%;\n    letter-spacing: 0.14px;\n}\n\nhr {\n    border-top: 1px solid #FF8307;\n}\n\n.articleTitle {\n    margin-bottom: 40px;\n}\n\n.articleImage {\n    margin-top: 40px;\n    margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleImage": "CharacteristicsOfPatientsWithEarlyBipolarDisorder_articleImage__2UTcP",
	"disclaimer": "CharacteristicsOfPatientsWithEarlyBipolarDisorder_disclaimer__1JYYU",
	"stamp": "CharacteristicsOfPatientsWithEarlyBipolarDisorder_stamp__35YR2",
	"articleTitle": "CharacteristicsOfPatientsWithEarlyBipolarDisorder_articleTitle__1DB3c"
};
export default ___CSS_LOADER_EXPORT___;
