import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Banner from "../../components/Banner/Banner";
import Video from "../../components/Video/Video";
import Paragraph from "../../components/Paragraph/Paragraph";
import Image from "../../components/Image/Image";
import References from "../../components/References/References";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import BrightcoveVideo from "../../components/BrightCove/BrightCove";
import banner from "../../assets/images/2024/homeHero.png";
import mobileBanner from "../../assets/images/about_mobile.jpg";
import videoImage from "../../assets/images/Group16.png";

import classes from "./About.module.css";

import { trackDigitalData } from "../../helpers/analytics";

const About = () => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    document.title = "RMS | About RMS";
    trackDigitalData('pageLoad', 'ats06044');
  });

  // const showVideoInModal = () => {
  //   setShowVideo(true);
  // };

  return (
    <Row>
      <Col>
        <Banner banner={banner} mobileBanner={mobileBanner} />
        <div style={{ zIndex: '9', marginLeft: 'calc(-50vw + 50% + 2px)', width: '100vw', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '100px' }} className="about-title-container">
          <h1 className={classes.aboutTitle}>Rapid Mood Screener</h1>
          <p className={classes.aboutDescription}>The Rapid Mood Screener (RMS) is a screening tool that was developed to differentiate bipolar I disorder from major depressive disorder (MDD) in patients with depressive symptoms who have been diagnosed with MDD.<sup>1</sup> </p>
        </div>
        <Row className="margin-to-banner main-container-padding">
          <Col>
            <BrightcoveVideo videoId="6364557291112" keys={{
              start: 'ats06096',
              replay: 'ats06102',
              resume: 'ats06103',
              pause: 'ats06097',
              '25%': 'ats06101',
              '50%': 'ats06100',
              '75%': 'ats06099',
              'End': 'ats06098'
            }} />
            <Paragraph rowClasses="mt-3">
              <h2 style={{ marginTop: '20px' }}>About the Rapid Mood Screener</h2>
              <p>
                The Rapid Mood Screener (RMS) is a screening tool that was
                developed to differentiate bipolar I disorder from major
                depressive disorder (MDD) in patients with depressive
                symptoms who have been diagnosed with MDD.
                <sup>1</sup> Created by a team of multidisciplinary experts
                including primary care clinicians, psychiatry nurse
                practitioners, psychiatrists, pharmacists, behavioral
                therapists, psychometricians, and health economists, the RMS
                provides a pragmatic approach to addressing the need for
                easy and accurate screening of bipolar I disorder. The
                screener was validated in a study of patients with bipolar I
                disorder and MDD and the publication can be accessed from
                the following{" "}
                <ExternalLink
                  href="https://www.tandfonline.com/doi/full/10.1080/03007995.2020.1860358"
                  classes="anchor"
                  trackId="ats06104"
                  backTrackId="ats06105"
                  confirmTrackId="ats06106"
                >
                  <u>journal website</u>
                </ExternalLink>
                .<sup>1</sup>
              </p>
            </Paragraph>

            <Paragraph rowClasses="mt-3">
              <p>
                The 6-item RMS not only screens for past manic symptoms that are
                the hallmark of a bipolar I disorder diagnosis, but it also
                evaluates depressive characteristics that are more likely to
                indicate bipolar I disorder than MDD. For example, the RMS
                screens for earlier age of depression onset, response to
                antidepressant treatment, and a high number of prior depressive
                episodes since these attributes may suggest bipolar I disorder.
                <sup>2,3</sup> Additionally, the RMS includes clearly worded
                manic symptom items to help clinicians identify past mood
                elevation or elicit confirmation of a prior manic episode. The
                RMS can be completed during a clinical visit or in another
                setting (e.g., online, electronic medical record system, waiting
                room).
              </p>
              <p style={{ fontStyle: 'italic' }}>
                The Rapid Mood Screener tool was developed with funding and input provided by AbbVie and external experts, who received financial support from AbbVie for research, honoraria and/or consulting services depending on the author.
              </p>
              <p className={classes.stamp}>ABBV-US-01776-MC, V1.0 | Approved 09/2024 | AbbVie Medical Affairs</p>
            </Paragraph>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <a href="/rms" style={{ backgroundColor: '#071D49', padding: '12px 29px', color: 'white', width: '338px', textAlign: 'center', fontWeight: 800, fontSize: '18px' }} className={classes.accessToolButton} onClick={() => trackDigitalData('Interaction', 'ats06107')}>ACCESS TOOL</a>
        </div>

        <Paragraph>
          <References trackId="ats06266" override={true}>
            <li>
              McIntyre RS, Patel MD, Masand PS, et al. The Rapid Mood Screener
              (RMS): A novel and pragmatic screener for bipolar I disorder.{" "}
              <i>Curr Med Res Opin</i>. 2021;37(1):135<span>&ndash;</span>144.
            </li>
            <li>
              Mitchell PB, Goodwin GM, Johnson GF, Hirschfeld RM. Diagnostic
              guidelines for bipolar depression: a probabilistic approach.{" "}
              <i>Bipolar Disord</i>. 2008;10(1 Pt 2):144<span>&ndash;</span>152.
            </li>
            <li>
              Hirschfeld RM. Differential diagnosis of bipolar disorder and
              major depressive disorder. <i>J Affect Disord</i>. 2014;169 Suppl
              1:S12<span>&ndash;</span>16.
            </li>
          </References>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default About;
