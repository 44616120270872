import { useState, useEffect } from "react";

import NewBanner from "../../components/NewBanner/NewBanner";

import "./CaseStudy1.css";

import img1 from "../../assets/ics/RMS-OpeningOpening_PatientCaseStudy_BPIDfromMDD_V2_Still.png";
import img2 from "../../assets/ics/RMS-DisclaimerCard-BPIDfromMDD.jpg";
import img3 from "../../assets/ics/Timeline.png";
import img4 from "../../assets/ics/RMS-scale.png";
import img5 from "../../assets/ics/Pie-Chart-Style_1.png";
import img6 from "../../assets/ics/RMS-EndCard-BPIDfromMDD.jpg";

const CaseStudy1 = () => {
    useEffect(() => {
        icsInit();
    }, []);
    return (
        <>
            <NewBanner title="Patient Case Navigator: Differentiated Bipolar I Disorder From Major Depressive Disorder" breadcrumbs={[{ title: "Clinical Education", link: null }, { title: "Interactive Case Study", link: null }]} center={true} />

            <p style={{ textAlign: "center" }}>Welcome to the Patient Case Navigator, an interactive learning experience for nurse practitioners. <br />
                Please use the "Next" or "Previous" buttons at the bottom of the page to move forward or backwards.</p>


            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <div id="cscontainer">

                <div id="csslides">

                    <div id="csslidesContainer">

                        <div className="csslide">

                            <img className="csfullSlide" src={img1} />
                        </div>


                        <div className="csslide">

                            <img className="csfullSlide" src={img2} />

                        </div>

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Introduction</h1>

                                </div>

                                <div className="csslideContainer">

                                    <div className="csfolder">

                                        <div className="cstabs">

                                            <div className="cstab csactivetab">

                                                <p>Learning Objectives</p>

                                            </div>

                                        </div>

                                        <div className="csfolderContent csactivecontent">

                                            <p className="csfolderTitle">Learning Objectives</p>

                                            <ul>

                                                <li>

                                                    Differentiating the characteristics between major depressive disorder (MDD) and

                                                    bipolar I disorder (BP-1) and challenges identifying these features

                                                </li>

                                                <li>

                                                    Probing questions to ask a patient with MDD who develops new symptoms

                                                </li>

                                                <li>

                                                    A patient-reported screening tool to help assess patients for BP-1

                                                </li>

                                                <li>

                                                    Potential management options for this patient after arriving at a new diagnosis

                                                </li>

                                            </ul>

                                        </div>

                                    </div>

                                    <div className="cssideVideo">

                                        <div className="csvideoHeading">

                                            <p>Watch the video:</p>

                                        </div>

                                        <iframe className="csvimeo"
                                            src="https://bcove.video/4hJYQVv"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="462"
                                            height="255">
                                        </iframe>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Patient Chart Review</h1>

                                </div>

                                <div className="csslideContainer">

                                    <div className="csfolder">

                                        <div className="cstabs">

                                            <div className="cstab csactivetab">

                                                <p>History of Present Illness</p>

                                            </div>

                                            <div className="cstab">

                                                <p>Medical History</p>

                                            </div>

                                            <div className="cstab">

                                                <p>Jenna's Timeline</p>

                                            </div>

                                        </div>

                                        <div className="csfolderContent csactivecontent">

                                            <p className="csfolderTitle">History of Present Illness</p>

                                            <p>

                                                Jenna is a 29-year-old woman who was previously diagnosed with major depressive

                                                disorder (MDD). Twelve weeks ago, she presented to her primary care physician, Dr

                                                Jones, with a chief complaint of depressed mood, diminished interest in her usual

                                                hobbies and activities, excessive sleeping, increased appetite, and weight gain. At

                                                that time, Jenna scored 19 on her Patient Health Questionnaire-9 (PHQ-9), which is

                                                suggestive of moderately severe depression, so she was diagnosed with undergoing a

                                                current major depressive episode (MDE). Jenna began pharmacotherapy with a serotonin

                                                and norepinephrine reuptake inhibitor (SNRI). After 4 weeks of initial SNRI therapy,

                                                Jenna reported mild improvements in her mood at a follow-up visit (PHQ-9 score of

                                                16), where a dose adjustment was made. Her second follow-up at week 8 resulted in a

                                                PHQ-9 score of 14 and another SNRI dose increase. Around week 10, however, she

                                                developed sleep troubles and some irritability.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">Medical History</p>

                                            <p>

                                                Jenna has intermittent asthma for which she uses an inhaler. She is adherent to her

                                                combined oral contraceptive pills for birth control and the SNRI that she started 12

                                                weeks ago. She has had 2 trials of selective serotonin reuptake inhibitors (SSRI)

                                                for MDD previously.

                                            </p>

                                            <p>

                                                Jenna drinks 1 to 2 cups of coffee in the morning daily. She has 1 to 2 alcoholic

                                                beverages weekly with her partner. Jenna denies a history of smoking or other drug

                                                use.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">Timeline of Jenna's Present Illness</p>

                                            <img className="expandable" src={img3} />

                                            <p className="csreference">PHQ-9 = Patient Health Questionnaire-9, ADT = antidepressant therapy</p>

                                        </div>

                                    </div>

                                    <div className="cssideVideo">

                                        <div className="csvideoHeading">

                                            <p>Watch the video:</p>

                                        </div>

                                        <iframe className="csvimeo"
                                            src="https://bcove.video/3Cst5QA"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="462"
                                            height="255">
                                        </iframe>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Patient Interview</h1>

                                </div>

                                <div className="csslideContainer">

                                    <div className="csfolder">

                                        <div className="cstabs">

                                            <div className="cstab csactivetab">

                                                <p>Current Symptoms</p>

                                            </div>

                                            <div className="cstab">

                                                <p>History of Episodes</p>

                                            </div>

                                        </div>

                                        <div className="csfolderContent csactivecontent">

                                            <p className="csfolderTitle">Evaluating Jenna's Current Symptoms <sup> 1-3 </sup>:</p>

                                            <ul>

                                                <li>

                                                    Changes in sleep

                                                </li>

                                                <li>

                                                    Changes in appetite

                                                </li>

                                                <li>

                                                    Depressive symptoms

                                                </li>

                                                <li>

                                                    Psychomotor retardation

                                                </li>

                                                <li>

                                                    Agitation/irritability/racing thoughts

                                                </li>

                                            </ul>

                                            <p className="csreference">

                                                1. American Psychiatric Association. <span className="csitalic">Diagnostic and

                                                    Statistical Manual of Mental Disorders.</span> 5th ed. American Psychiatric

                                                Publishing; 2013. <br />

                                                2. Bobo WV. <span className="csitalic">Mayo Clin Proc.</span> 2017;92(10):1532-1551.

                                                <br />

                                                3. Yatham LN, et al. <span className="csitalic">Bipolar Disord.</span> 2018;20:97-127.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">Episode Evaluation</p>

                                            <ul>

                                                <li>

                                                    # of lifetime manic/depressive episodes

                                                </li>

                                                <li>

                                                    Index episode

                                                </li>

                                                <li>

                                                    Last episode

                                                </li>

                                                <li>

                                                    Triggers/precipitants

                                                </li>

                                                <li>

                                                    Age of onset

                                                </li>

                                            </ul>

                                            <table className="cstable3">
                                                <tbody>
                                                    <tr>

                                                        <th className="cstable3header">Episode Evaluation</th>

                                                    </tr>

                                                    <tr>

                                                        <td>

                                                            # of lifetime manic/depressive episodes

                                                        </td>

                                                    </tr>

                                                    <tr>

                                                        <td>

                                                            Index episode

                                                        </td>

                                                    </tr>

                                                    <tr>

                                                        <td>

                                                            Last episode

                                                        </td>

                                                    </tr>

                                                    <tr>

                                                        <td>

                                                            Triggers/precipitants

                                                        </td>

                                                    </tr>

                                                    <tr>

                                                        <td>

                                                            Age of onset

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                            <p className="csreference">

                                                1. The Psychiatric Interview. Psych DB. 2024. <br />

                                                2. Mitchell PB, et al. <span className="csitalic"> Bipolar Disord </span>.
                                                2008;10(1Pt2):144-152.

                                            </p>

                                        </div>

                                    </div>

                                    <div className="cssideVideo">

                                        <div className="csvideoHeading">

                                            <p>Watch the video:</p>

                                        </div>

                                        <iframe className="csvimeo"
                                            src="https://bcove.video/4eydR9Y"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="462"
                                            height="255">
                                        </iframe>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="csslide scroll">

                            <div>

                                <div className="csslideTitle csQuizTitle">

                                    <h1>Quiz #1</h1>

                                </div>

                                <div className="csquiz">

                                    <iframe data-rid-id="PEXS2uBq" width="1095" height="800"
                                        src="https://www.riddle.com/embed/a/PEXS2uBq?fixed=1"></iframe>

                                </div>

                            </div>

                        </div >

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Current Medical History</h1>

                                    <div className="cscenterVideo">

                                        <iframe className="csinterview-vid csvimeo"
                                            src="https://bcove.video/4eydR9Y"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="790"
                                            height="455">
                                        </iframe>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="csslide scroll">

                            <div>

                                <div className="csslideTitle csQuizTitle">

                                    <h1>Quiz #2</h1>

                                </div>

                                <div className="csquiz">

                                    <iframe data-rid-id="Z64wUvJ9" width="1095" height="800"
                                        src="https://www.riddle.com/embed/a/Z64wUvJ9?fixed=1"></iframe>

                                </div>

                            </div>

                        </div >

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Introducing the Rapid Mood Screener</h1>

                                </div>

                                <div className="csslideContainer">

                                    <div className="csfolder">

                                        <div className="cstabs">

                                            <div className="cstab csactivetab">

                                                <p>Screening for BP-1</p>

                                            </div>

                                            <div className="cstab">

                                                <p>Rapid Mood Screener</p>

                                            </div>

                                            <div className="cstab">

                                                <p>New Diagnosis</p>

                                            </div>

                                            <div className="cstab">

                                                <p>BP-1 Symptoms Prevalence</p>

                                            </div>

                                        </div>

                                        <div className="csfolderContent csactivecontent">

                                            <p className="csfolderTitle">Screening for BP-1</p>

                                            <p>

                                                During the patient interview, given his suspicions on a diagnosis other than MDD,

                                                Tony has Jenna fill out the Rapid Mood Screener (RMS), a self-administered screening

                                                tool designed to help differentiate BP-1 from MDD in patients with depressive

                                                <br /> symptoms.<sup>1</sup>

                                            </p>

                                            <p className="csreference">

                                                1. McIntyre RS, et al. <span className="csitalic">Curr Med Res Opin.</span>

                                                2021;37(1):135-144.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">Rapid Mood Screener</p>

                                            <img className="expandable" src={img4}></img>

                                            <p className="csreference">

                                                The RMS is not a diagnostic tool. The RMS tool was developed with funding and input

                                                provided by AbbVie and external experts, who received financial support from AbbVie

                                                for research, honoraria and/or consulting services depending on the

                                                author.<br /><br />

                                                1. McIntyre RS, et al. <span className="csitalic">Curr Med Res Opin.</span>
                                                2021;37(1):135-144.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">New Diagnosis</p>

                                            <p>

                                                Based on Jenna's and her partner's combined report, as well as her physical

                                                examination and medical history, Tony considers the shift in mood and new symptoms

                                                as potentially indicative of a different diagnosis. Jenna's response to her SNRI

                                                regimens is particularly suggestive, as patients with BP-1 who are started on an

                                                antidepressant may undergo a treatment-emergent affective switch (TEAS).

                                            </p>

                                            <p>

                                                TEAS: A switch in mood polarity from depression to mania or hypomania that can be

                                                induced by certain medications, including antidepressant monotherapy<sup>1,2</sup>

                                            </p>

                                            <p className="csreference">

                                                1. Moller HJ, et al. <span className="csitalic"> Expert Rev Neurother.</span> 2004;4(6
                                                Suppl 2):S3-S8. <br />

                                                2. Salvadore G, et al. <span className="csitalic">J Clin Psychiatry.</span>
                                                2010;71(11):1488-1501.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">BP-1 Symptoms Prevalence</p>

                                            <ul>

                                                <li>

                                                    Symptoms of depression in BP-1 closely resemble those in MDD.<sup>1</sup>

                                                </li>

                                                <li>

                                                    Depressive symptoms are the most commonly reported symptom in patients with

                                                    BP-1.<sup>2</sup>

                                                </li>

                                                <li>

                                                    Patients with BP-1 may experience multiple depressive episodes in their

                                                    lifetime.<sup>3</sup>

                                                </li>

                                            </ul>

                                            <img className="expandable noborder" src={img5}></img>

                                            <p className="csreference">

                                                *National Institute of Mental Health Collaborative Depression Study (1978 to 1981):

                                                weekly symptom severity level based on the 6-point LIFE Psychiatric Status Rating

                                                (PSR) scale for depression and mania plus the 3-point PSR scale for rating minor

                                                depression/dysthymia, hypomania, <span className="csitalic">DSM-4</span> atypical depression, <span className="csitalic">DSM-3</span> adjustment

                                                disorder with depressed mood, and Research Diagnostic Criteria cyclothymic

                                                personality. <br /><br />



                                                1. Culpepper L. < span className="csitalic" > Prim Care Companion CNS Disord.</span >
                                                2014; 16(3):PCC.13r01609. < br />

                                                2. Judd LL, et al. < span className="csitalic" > Arch Gen Psychiatry.</span >
                                                2002; 59: 530 - 537. < br />

                                                3. Yatham LN, et al. < span className="csitalic" > Bipolar Disord.</span > 2018; 1 - 74.

                                            </p >

                                        </div >

                                    </div >

                                    <div className="cssideVideo">

                                        <div className="csvideoHeading">

                                            <p>Watch the video:</p>

                                        </div>

                                        <iframe className="csvimeo"
                                            src="https://bcove.video/3AJCD9l"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="462"
                                            height="255">
                                        </iframe>

                                    </div>

                                </div >

                            </div >

                        </div >

                        <div className="csslide scroll">

                            <div>

                                <div className="csslideTitle csQuizTitle">

                                    <h1>Quiz #3</h1>

                                </div>

                                <div className="csquiz">

                                    <iframe data-rid-id="5WtuP1k6" width="1095" height="800"
                                        src="https://www.riddle.com/embed/a/5WtuP1k6?fixed=1"></iframe>

                                </div>

                            </div>

                        </div >

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Jenna's Management Plan</h1>

                                </div>

                                <div className="csslideContainer">

                                    <div className="csfolder">

                                        <div className="cstabs">

                                            <div className="cstab csactivetab">

                                                <p>Management Plan</p>

                                            </div>

                                        </div>

                                        <div className="csfolderContent csactivecontent">

                                            <p className="csfolderTitle">Management Plan</p>

                                            <p>

                                                Tony arrives at a diagnosis of bipolar I disorder and establishes a new management

                                                plan. He suggests starting Jenna on an atypical antipsychotic. Jenna objects at

                                                first, becoming agitated and stating that she is not psychotic. Tony dispels the

                                                stigma by explaining the history of the medication class. He elaborates on the

                                                rationale behind his thinking and discusses the safety and tolerability aspects of

                                                the medication class. He additionally suggests starting cognitive behavioral therapy

                                                (CBT). He schedules a follow-up in 2 weeks to check how the new medication is

                                                working for her.<sup>1</sup>

                                            </p>

                                            <p className="csreference">

                                                1. American Psychiatric Association.<span className="csitalic"> Practice Guidelines for
                                                    the Treatment of

                                                    Patients with Bipolar Disorder.</span> 2nd ed. American Psychiatric Association;
                                                2002.

                                            </p>

                                        </div>

                                    </div>

                                    <div className="cssideVideo">

                                        <div className="csvideoHeading">

                                            <p>Watch the video:</p>

                                        </div>

                                        <iframe className="csvimeo"
                                            src="https://bcove.video/4eu3jJ7"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="462"
                                            height="255">
                                        </iframe>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="csslide scroll">

                            <div>

                                <div className="csslideTitle csQuizTitle">

                                    <h1>Quiz #4</h1>

                                </div>

                                <div className="csquiz">

                                    <iframe data-rid-id="szVHx9xT" width="1095" height="800"
                                        src="https://www.riddle.com/embed/a/szVHx9xT?fixed=1"></iframe>

                                </div>

                            </div>

                        </div >

                        <div className="csslide">

                            <div>

                                <div className="csslideTitle">

                                    <h1>Conclusion</h1>

                                </div>

                                <div className="csslideContainer">

                                    <div className="csfolder">

                                        <div className="cstabs fiveTabs">

                                            <div className="cstab csactivetab">

                                                <p>Summary Points</p>

                                            </div>

                                            <div className="cstab">

                                                <p>Predictive Factors</p>

                                            </div>

                                            <div className="cstab">

                                                <p>Manic Symptoms</p>

                                            </div>

                                            <div className="cstab">

                                                <p>RMS</p>

                                            </div>

                                            <div className="cstab">

                                                <p>Treatment Options</p>

                                            </div>

                                        </div>

                                        <div className="csfolderContent csactivecontent">

                                            <p className="csfolderTitle">Summary Points</p>

                                            <ul>

                                                <li>

                                                    A study showed that patients with bipolar I disorder may spend 32% of the time

                                                    in a depressed state compared to 9% of the time in a manic state over the course

                                                    of their illness. Also, BP-1 patients usually present to a primary care

                                                    physician when experiencing depressive symptoms.<sup>1</sup>

                                                </li>

                                                <li>

                                                    Patients can experience a delay of up to 5 to 10 years between symptom onset and

                                                    receiving a correct diagnosis of BP-1.<sup>2</sup>

                                                </li>

                                                <li>

                                                    Treatment-emergent affective switch (TEAS) is a switch in mood polarity from

                                                    depression to mania or hypomania that is induced by certain medications,

                                                    including antidepressant monotherapy.<sup>3,4</sup>

                                                </li>

                                            </ul>

                                            <p className="csreference">

                                                1. Judd LL, et al.<span className="csitalic"> Arch Gen Psychiatry. </span>
                                                2002;59(6):530-537.<br />

                                                2. Phillips ML, et al. <span className="csitalic"> Lancet. </span>
                                                2013;381(9878):1663-1671.<br />

                                                3. Moller HJ, et al. <span className="csitalic"> Expert Rev Neurother. </span> 2004;4(6
                                                Suppl 2):S3-S8.<br />

                                                4. Salvadore G, et al. <span className="csitalic"> J Clin Psychiatry. </span>
                                                2010;71(11):1488-1501.

                                            </p>



                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">Differential Features of MDD and BP-1</p>

                                            <p className="csbold">Major Depressive Disorder</p>

                                            <ul className="csredBox">

                                                <li>

                                                    Negative family history of bipolar disorder

                                                </li>

                                                <li>

                                                    Initial insomnia/reduced sleep

                                                </li>

                                                <li>

                                                    Appetite and/or weight loss

                                                </li>

                                                <li>

                                                    Normal or increased activity levels

                                                </li>

                                                <li>

                                                    Somatic complaints

                                                </li>

                                                <li>

                                                    Later onset of first episode (&gt; 25 years)

                                                </li>

                                                <li>

                                                    Fewer prior episodes (&lt; 5 episodes) </li>

                                                <li>

                                                    Longer duration of episode (&gt; 6 months)

                                                </li>

                                            </ul>

                                            <hr />

                                            <p className="csbold">Bipolar Disorder</p>

                                            <ul className="csredBoxQuestion">

                                                <li>

                                                    Positive family history of bipolar disorder

                                                </li>

                                                <li>

                                                    Hypersomnia/increased daytime napping

                                                </li>

                                                <li>

                                                    Increased appetite/weight gain

                                                </li>

                                                <li>

                                                    Atypical depressive symptoms

                                                </li>

                                                <li>

                                                    Psychomotor retardation

                                                </li>

                                                <li>

                                                    Manic symptoms present (eg, agitation/irritability/racing thoughts)

                                                </li>

                                                <li>

                                                    Earlier onset of first episode (&lt; 25 years) </li>

                                                <li>

                                                    Multiple prior episodes (&#8805 5 episodes)

                                                </li>

                                            </ul>

                                            <p className="csreference">

                                                This is not a diagnostic tool and is not intended to replace a clinical evaluation

                                                by a healthcare provider. <br /><br />



                                                1. American Psychiatric Association. <span className="csitalic"> Diagnostic and
                                                    Statistical Manual of Mental

                                                    Disorders. </span> 5th ed. American Psychiatric Publishing; 2013.<br />

                                                2. Bobo WV. <span className="csitalic"> Mayo Clin Proc. </span>
                                                2017;92(10):1532-1551.<br />

                                                3. Yatham LN, et al. < span className="csitalic" > Bipolar Disord. </span > 2018; 20: 97 - 127.

                                            </p >

                                        </div >

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">APA Diagnostic Criteria for Mania</p>

                                            <p>

                                                A distinct period of abnormally and persistently elevated, expansive, or irritable

                                                mood and abnormally and persistently increased goal-directed activity or energy,

                                                lasting <span className="csbold">at least 1 week</span> and present most of the day,
                                                nearly every day (or any

                                                duration if hospitalization is necessary).

                                            </p>

                                            <p>

                                                During the period of mood disturbance and increased energy or activity, 3 (or more)

                                                of the following symptoms (4 if the mood is only irritable) are present to a

                                                significant degree and represent a noticeable change from usual

                                                behavior<sup>1</sup>:

                                            </p>

                                            <ul>

                                                <li>

                                                    Inflated self-esteem or grandiosity

                                                </li>

                                                <li>

                                                    Decreased need for sleep

                                                </li>

                                                <li>

                                                    Racing thoughts

                                                </li>

                                                <li>

                                                    Distractibility

                                                </li>

                                                <li>

                                                    Increase in goal-directed activity (this may be socially, at work or school, or

                                                    sexually) or psychomotor agitation or restlessness

                                                </li>

                                                <li>

                                                    Excessive involvement in activities that have a high potential for painful

                                                    consequences

                                                </li>

                                            </ul>

                                            <p className="csreference">

                                                1. American Psychiatric Association. <span className="csitalic"> Diagnostic and
                                                    Statistical Manual of Mental

                                                    Disorders. </span> 5th ed. American Psychiatric Publishing; 2013.

                                            </p>

                                        </div>

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">RMS</p>

                                            <ul>

                                                <li>

                                                    The Rapid Mood Screener (RMS) is a pragmatic, self-administered screening tool

                                                    designed to differentiate BP-1 from MDD in patients who have already been

                                                    diagnosed with MDD.

                                                </li>

                                                <li>

                                                    It contains 6 "Yes" or "No" questions that evaluate 3 symptoms of depression and

                                                    3 symptoms of mania.

                                                </li>

                                                <li>

                                                    A patient answering "Yes" to 4 or more items signals a very high likelihood of

                                                    having BP-1.

                                                </li>

                                                <li>

                                                    When compared to the Mood Disorder Questionnaire (MDQ), the RMS demonstrated a

                                                    sensitivity of 88% and a specificity of 80%, while those of the MDQ were 86% and

                                                    78%, respectively.

                                                </li>


                                                <li>

                                                    A positive screen on the RMS would subsequently require a more comprehensive

                                                    evaluation by a mental healthcare provider to confirm a BP-1

                                                    diagnosis.<sup>1</sup>

                                                </li>

                                            </ul>

                                            <p className="csreference">

                                                The Rapid Mood Screener (RMS) is not a diagnostic tool. The RMS tool was developed

                                                with funding and input provided by AbbVie and external experts, who received

                                                financial support from AbbVie for research, honoraria and/or consulting services

                                                depending on the author. <br /> <br />



                                                1. McIntyre RS, et al. < span className="csitalic" > Curr Med Res Opin.</span >
                                                2021; 37(1): 135 - 144.

                                            </p >

                                        </div >

                                        <div className="csfolderContent">

                                            <p className="csfolderTitle">Treatment Options</p>

                                            <p className="csbold">
                                                APA Management Options for Bipolar I Disorder
                                            </p>

                                            <ul>

                                                <li>

                                                    Pharmacotherapy

                                                    <ul>

                                                        <li>

                                                            Mood stabilizers

                                                        </li>

                                                        <li>

                                                            Antipsychotic medications

                                                        </li>

                                                    </ul>

                                                </li>

                                                <li>

                                                    Monotherapy with antidepressants is not recommended.

                                                </li>

                                                <li>

                                                    Psychotherapy, in addition to pharmacotherapy, has also been shown to help

                                                    improve patient outcomes.<sup>1</sup>

                                                </li>

                                            </ul>

                                            <p className="csreference">

                                                1. American Psychiatric Association. <span className="csitalic"> Practice Guideline for
                                                    the Treatment of

                                                    Patients with Bipolar Disorder.</span> 2nd ed. American Psychiatric Association;
                                                2002.

                                            </p>

                                        </div>

                                    </div >


                                    <div className="cssideVideo">

                                        <div className="csvideoHeading">

                                            <p>Watch the video:</p>

                                        </div>

                                        <iframe className="csvimeo"
                                            src="https://bcove.video/3YQfkmt"
                                            allowFullScreen
                                            allow="encrypted-media"
                                            width="462"
                                            height="255">
                                        </iframe>

                                    </div>

                                </div >

                            </div >

                        </div >

                        <div className="csslide">

                            <div>

                                <img id="csoutroImg" className="csfullSlide" src={img6} />

                            </div>

                        </div>

                    </div >

                </div >

                <div id="cscontrols">

                    <button id="csreset">Reset</button>

                    <div id="csprogress">

                        <div id="csprogressindicator"></div>

                    </div>

                    <div id="csbuttonGroup">

                        <button id="csstart">Start</button>

                        <button id="csprevious">Previous</button>

                        <button id="csnext">Next</button>

                    </div>

                </div>

                < br />

                <div id="csdisclosure">

                    <p>Alan "Tony" Amberg is a paid consultant for AbbVie Medical Affairs and was compensated for his time.</p>

                </div>

                <div className="csoverlay" id="csoverlay"></div>

                <div className="csmodal" id="csimageModal">
                    <button className="csclose" id="cscloseModal">X</button>
                    <img id="csmodalImage" src="" />
                </div>

            </div >
        </>
    );
};

function icsInit() {
    if (window.location.href.includes("case-study-1")) {
        let slides = document.getElementsByClassName('csslide');
        let progressIndex = 0;
        let progressIndicator = document.getElementById('csprogressindicator') || null;
        let currentPosition = 0;
        let progressBars;

        progressIndicator.innerHTML = (progressIndex + 1) + "/" + slides.length || null;

        for (let i = 0; i < slides.length - 1; i++) {
            let progressBar = document.createElement('div');
            progressBar.classList.add('cpbar');
            document.getElementById('csprogress').appendChild(progressBar);
        }

        progressBars = document.getElementsByClassName('cpbar');

        alignProgressBar();

        document.getElementById('csstart').onclick = function () {
            progressIndex++;
            document.cookie = "csposition2=" + progressIndex;
            alignProgressBar();
            moveSlide('forward');
        }

        document.getElementById('csnext').onclick = function () {
            if (progressIndex !== progressBars.length) {
                progressIndex++;
                document.cookie = "csposition2=" + progressIndex;
                alignProgressBar();
                moveSlide('forward');
                let videos = document.getElementsByClassName('csvimeo');
                for (let i = 0; i < videos.length; i++) {
                    videos[i].src = videos[i].src;
                }
            }
        }

        document.getElementById('csprevious').onclick = function () {
            progressIndex--;
            document.cookie = "csposition2=" + progressIndex;
            alignProgressBar();
            moveSlide('backward');
            let videos = document.getElementsByClassName('csvimeo');
            for (let i = 0; i < videos.length; i++) {
                videos[i].src = videos[i].src;
            }
        }

        document.getElementById('csreset').onclick = function () {
            document.cookie = "csposition2=0";
            window.location.reload();
        }

        document.getElementById('csoutroImg').onclick = function () {
            document.cookie = "csposition2=0";
            window.location.reload();
        }

        let cookies = getCookiesAsObject();

        if (cookies['csposition2']) {
            progressIndex = parseInt(cookies['csposition2']);
            progressIndicator.innerHTML = (progressIndex + 1) + "/" + slides.length;
            alignProgressBar();
            for (let i = 0; i < progressIndex; i++) {
                moveSlide('forward');
            }
        }

        function getCookiesAsObject() {
            const cookieString = document.cookie;
            const cookieArray = cookieString.split('; ');
            const cookies = {};
            cookieArray.forEach(cookie => {
                const [key, value] = cookie.split('=');
                cookies[decodeURIComponent(key)] = decodeURIComponent(value);
            });
            return cookies;
        }

        let tabs = document.getElementsByClassName('cstab');

        for (let i = 0; i < tabs.length; i++) {
            tabs[i].onclick = function () {
                let allTabs = this.parentElement.querySelectorAll('.cstab');
                let allContent = this.parentElement.parentElement.querySelectorAll('.csfolderContent');
                for (let j = 0; j < allTabs.length; j++) {
                    if (allTabs[j] !== this) {
                        allTabs[j].classList.remove('csactivetab');
                        allContent[j].classList.remove('csactivecontent');
                    } else {
                        this.classList.add('csactivetab');
                        allContent[j].classList.add('csactivecontent')
                    }
                }
            }
        }

        function alignProgressBar() {
            progressIndicator.innerHTML = (progressIndex + 1) + "/" + slides.length;
            for (let i = 0; i < progressBars.length; i++) {
                if (i < progressIndex) {
                    progressBars[i].style.backgroundColor = "#e89748";
                } else {
                    progressBars[i].style.backgroundColor = "#D0D0D0";
                }
            }

            setTimeout(() => {
                if (progressIndex !== progressBars.length) {
                    progressIndicator.style.left = (progressBars[progressIndex].offsetLeft - 25) + 'px';
                } else {
                    progressIndicator.style.left = (progressBars[progressIndex - 1].offsetLeft + 25) + 'px';
                }
            }, 100);
        }

        function moveSlide(direction) {
            if (progressIndex === 0) {
                document.getElementById('csprevious').style.display = 'none';
                document.getElementById('csnext').style.display = 'none';
                document.getElementById('csstart').style.display = 'block';
            } else if (progressIndex === progressBars.length) {
                document.getElementById('csstart').style.display = 'none';
                document.getElementById('csnext').style.display = 'none';
                document.getElementById('csprevious').style.display = 'block';
            } else {
                document.getElementById('csstart').style.display = 'none';
                document.getElementById('csnext').style.display = 'block';
                document.getElementById('csprevious').style.display = 'block';
            }

            if (direction === 'forward') {
                currentPosition -= 1127;
                document.getElementById('csslidesContainer').style.marginLeft = currentPosition + "px";
            } else {
                currentPosition += 1127;
                document.getElementById('csslidesContainer').style.marginLeft = currentPosition + "px";
            }
        }

        const modal = document.getElementById('csimageModal');
        const modalImage = document.getElementById('csmodalImage');
        const closeModal = document.getElementById('cscloseModal');
        const overlay = document.getElementById('csoverlay');

        document.querySelectorAll('.expandable').forEach(img => {
            img.addEventListener('click', function () {
                modalImage.src = this.src;
                modal.style.display = 'flex';
                overlay.style.display = 'block';
            });
        });

        closeModal.addEventListener('click', function () {
            modal.style.display = 'none';
            overlay.style.display = 'none';
        });

        modal.addEventListener('click', function (e) {
            if (e.target === modal) {
                modal.style.display = 'none';
                overlay.style.display = 'none';
            }
        });

        overlay.addEventListener('click', function () {
            modal.style.display = 'none';
            overlay.style.display = 'none';
        });

        window.addEventListener("resize", alignProgressBar);
    }
}

export default CaseStudy1;
