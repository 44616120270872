import ExternalLink from "../../ExternalLink/ExternalLink";

import classes from "./Footer.module.css";

import privacyIcon from '../../../assets/images/privacyChoicesIcon.png';

import { trackDigitalData } from "../../../helpers/analytics";

const Footer = () => (
  <>
    <div className={`footer text-center ${classes.footerContainer}`}>
      <div className={classes.footerLink}>
        <ul>
          <li>
            <ExternalLink href="https://www.abbvie.com/our-company.html" trackId="ats06070" backTrackId="ats06084" confirmTrackId="ats06086">
              About
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.abbvie.com/accessibility-statement.html" trackId="ats06072" backTrackId="ats06078" confirmTrackId="ats06079">
              Accessibility Statement
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.abbvie.com/contactus.html" trackId="ats06071" backTrackId="ats06073" confirmTrackId="ats06074">
              Contact Us
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://www.abbvie.com/termsofuse.html" trackId="ats06075" backTrackId="ats06082" confirmTrackId="ats06083">
              Terms of Use
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" trackId="ats06081" backTrackId="ats06076" confirmTrackId="ats06077">
              Privacy Notice
            </ExternalLink>
          </li>

          <li>
            <span id="ot-sdk-btn" onClick={() => trackDigitalData('interaction', 'ats06085', true)}>Cookies Settings</span>
          </li>
          <li className={classes.yourPrivacyChoices}>
            <ExternalLink href="https://abbviemetadata.my.site.com/AbbvieDSRM/" trackId="ats06080" backTrackId="ats06087" confirmTrackId="ats06088">
              Your Privacy Choices
            </ExternalLink>
          </li>
          <img src={privacyIcon} alt="" className={classes.newPrivacyIcon} />
        </ul>
      </div>
      <div className={`text-center ${classes.copyrightText}`}>
        <p>
          <span>&copy;</span> {new Date().getFullYear()} AbbVie. All rights reserved.
        </p>
      </div>
    </div>
  </>
);

export default Footer;
