// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Characteristics_characteristics__2R36v td {\n    padding: 8px;\n}\n  \n.Characteristics_characteristics__2R36v th {\n    text-align: center;\n}\n\n.Characteristics_characteristics__2R36v tbody tr:nth-child(odd) {\n    background-color: #fdcd9d;\n}\n\n.Characteristics_characteristics__2R36v tbody tr:nth-child(even) {\n    background-color: #FFF4E8;\n}\n\n@media only screen and (max-width: 768px) {\n    .Characteristics_rms-items__fzPAu {\n        display: block;\n    }\n}", "",{"version":3,"sources":["webpack://src/containers/Development/Characteristics/Characteristics.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".characteristics td {\n    padding: 8px;\n}\n  \n.characteristics th {\n    text-align: center;\n}\n\n.characteristics tbody tr:nth-child(odd) {\n    background-color: #fdcd9d;\n}\n\n.characteristics tbody tr:nth-child(even) {\n    background-color: #FFF4E8;\n}\n\n@media only screen and (max-width: 768px) {\n    .rms-items {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"characteristics": "Characteristics_characteristics__2R36v",
	"rms-items": "Characteristics_rms-items__fzPAu"
};
export default ___CSS_LOADER_EXPORT___;
