// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".References_paragraph__2xE62 {\n    margin-left: -75px;\n    margin-right: -75px;\n}", "",{"version":3,"sources":["webpack://src/components/References/References.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".paragraph {\n    margin-left: -75px;\n    margin-right: -75px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": "References_paragraph__2xE62"
};
export default ___CSS_LOADER_EXPORT___;
