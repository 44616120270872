import { useState, useEffect } from "react";

import NewBanner from "../../components/NewBanner/NewBanner";
import Featured from "../../components/Featured/Featured";
import ContentListing from "../../components/ContentListing/ContentListing";

import featuredImage from "../../assets/images/2024/quickGuidesForProviders/featured.png";

/*
import contentListingImage0 from "../../assets/images/2024/patientCaseQuizzes/contentListingImage0.png";
import contentListingImage1 from "../../assets/images/2024/patientCaseQuizzes/contentListingImage1.png";
*/

const QuickGuidesForProviders = () => {
    return (
        <>
            <NewBanner title="Quick Guides for Providers" breadcrumbs={[{title: "Bipolar I Disorder", link: null}, {title: "Quick Guides for Providers", link: null}]} />
            <Featured
                image={featuredImage}
                title="Distinguishing the Features of Bipolar I Disorder and Major Depressive Disorder"
                type="Quick Guide"
                description="Differentiating between unipolar (major depressive disorder) and bipolar I depression is challenging. Depressive symptoms are the most frequently reported symptoms in individuals with bipolar I disorder, and they are almost identical to those in individuals with unipolar depression. This printable 1-page guide was created to help you find the information you need quickly and easily."
                link="/pdfs/Distinguishing_the_Features_of_Bipolar_I_Disorder_and_Major_Depressive_Disorder.pdf"
                buttonText="DOWNLOAD NOW"
                target="_blank"
            />
            {/*<ContentListing content={
                [
                    {
                        image: contentListingImage0,
                        title: "Screening Patients With Bipolar Disorder in Practice",
                        type: "Patient Case Quiz",
                        description: "Does your patient have bipolar disorder, or another condition? Review this patient case to test your knowledge.",
                        link: "#"
                    },
                ]
            }/>*/}
        </>
    );
};

export default QuickGuidesForProviders;
