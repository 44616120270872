import { useState, useEffect } from "react";

import { trackDigitalData } from "../../helpers/analytics";

import NewBanner from "../../components/NewBanner/NewBanner";
import Featured from "../../components/Featured/Featured";
import ContentListing from "../../components/ContentListing/ContentListing";

import featuredImage from "../../assets/images/2024/patientCaseQuizzes/featured.png";

/*
import contentListingImage0 from "../../assets/images/2024/patientCaseQuizzes/contentListingImage0.png";
import contentListingImage1 from "../../assets/images/2024/patientCaseQuizzes/contentListingImage1.png";
*/

const PatientCaseQuizzes = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06090');
    }, []);
    return (
        <>
            <NewBanner title="Patient Case Quizzes" breadcrumbs={[{title: "Clinical Experience", link: null}, {title: "Patient Case Quizzes", link: null}]} />
            <Featured
                image={featuredImage}
                title="Screening Patients With Bipolar Disorder in Practice"
                type="Patient Case Quiz"
                description="Does your patient have bipolar disorder, or another condition? Review this patient case to test your knowledge."
                link="/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice"
                buttonText="TAKE QUIZ"
                trackId="ats06244"
            />
            {/*<ContentListing content={
                [
                    {
                        image: contentListingImage0,
                        title: "Screening Patients With Bipolar Disorder in Practice",
                        type: "Patient Case Quiz",
                        description: "Does your patient have bipolar disorder, or another condition? Review this patient case to test your knowledge.",
                        link: "#"
                    },
                ]
            }/>*/}
        </>
    );
};

export default PatientCaseQuizzes;
