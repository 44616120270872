import React from "react";

import classes from "./Quiz.module.css";

const Quiz = (props) => {
    return (
        <>
            <div className={classes.quizContainer}>  
                <iframe className={classes.quizIframe} src={props.embedCode} title="Quiz" />
            </div>
        </>
    );
};

export default Quiz;
