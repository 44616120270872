import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import NewBanner from "../../components/NewBanner/NewBanner";
import References from "../../components/References/References";
import Download from "../../components/Download/Download";
import RMSGuide from "./RMSGuide/RMSGuide";
import ClinicalUtility from "./ClinicalUtility/ClinicalUtility";
import Scoring from "./Scoring/Scoring";

import banner from "../../assets/images/clinician_banner.jpg";
import mobileBanner from "../../assets/images/clinician_mobile.jpg";

const ClinicianGuide = () => {
  useEffect(() => {
    document.title = "RMS | Clinician Guide";
  });

  return (
    <Row>
      <Col>
        <NewBanner title="Clinician Guide" breadcrumbs={[{}]} />

        <RMSGuide />

        <ClinicalUtility />

        <Scoring />

        <Row className="main-container-padding download-button-alignment">
          <Col>
            <Download pdfName="Clinician_Guide" />
          </Col>
        </Row>

        <References>
          <li>
            Hirschfeld RM, Lewis L, Vornik LA. Perceptions and impact of bipolar
            disorder: how far have we really come? Results of the national
            depressive and manic-depressive association 2000 survey of
            individuals with bipolar disorder. <i>J Clin Psychiatry</i>.
            2003;64(2):161<span>&ndash;</span>174.
          </li>
          <li>
            McIntyre RS, Patel MD, Masand PS, et al. The Rapid Mood Screener
            (RMS): A novel and pragmatic screener for bipolar I disorder.{" "}
            <i>Curr Med Res Opin</i>. 2021;37(1):135<span>&ndash;</span>144.
          </li>
        </References>
      </Col>
    </Row>
  );
};

export default ClinicianGuide;
