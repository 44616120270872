import { useState, useEffect } from "react";

import NewBanner from "../../components/NewBanner/NewBanner";
import Featured from "../../components/Featured/Featured";
import ContentListing from "../../components/ContentListing/ContentListing";

import featuredImage from "../../assets/images/2024/multimedia/featured.png";

import { trackDigitalData } from "../../helpers/analytics";

/*
import contentListingImage0 from "../../assets/images/2024/multimedia/contentListingImage0.png";
import contentListingImage1 from "../../assets/images/2024/multimedia/contentListingImage1.png";
*/

const Multimedia = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06091');
    }, []);
    return (
        <>
            <NewBanner title="Multimedia" breadcrumbs={[{ title: "Clinical Experience", link: null }, { title: "Multimedia", link: null }]} />
            <Featured
                image={featuredImage}
                title="Introducing the Rapid Mood Screener Tool"
                type="Video"
                description="The Rapid Mood Screener (RMS) is a brief, self-administered screening tool for bipolar I disorder. In this video, Dr Roger McIntyre reviews the utility of the RMS in a busy clinical setting. <br /><br /> <span class='smallText'>ABBV-US-00773-MC, V2.0 | Approved 01/2024 | AbbVie Medical Affairs</span>"
                link="/multimedia/introducing-the-rapid-mood-screener"
                buttonText="WATCH NOW"
                trackId="ats06267"
            />
            {/*<ContentListing content={
                [
                    {
                        image: contentListingImage0,
                        title: "Screening Patients With Bipolar Disorder in Practice",
                        type: "Patient Case Quiz",
                        description: "Does your patient have bipolar disorder, or another condition? Review this patient case to test your knowledge.",
                        link: "#"
                    },
                ]
            }/>*/}
        </>
    );
};

export default Multimedia;
