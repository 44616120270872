import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { SlideDown } from "react-slidedown";

import Expand from "../../../components/Expand/Expand";
import AnimationVideo from "../../../components/AnimationVideo/AnimationVideo";

import { MOBILE_VIEW } from "../../../config/config";

import classes from "./Symptoms.module.css";

import symptomsVideo from "../../../assets/videos/symptoms.mp4";
import group150 from "../../../assets/videos/popup.mp4";
import group148 from "../../../assets/images/Group 148.png";

/**
 * React functional component for symptoms view
 * @returns <JSX />
 */
const Symptoms = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= MOBILE_VIEW
  );
  const [showImagePopup, setShowImagePopup] = useState(false);

  useEffect(() => {
    const updateMobileView = () => {
      setIsMobileView(window.innerWidth <= MOBILE_VIEW);
    };

    window.addEventListener("resize", updateMobileView);

    return () => window.removeEventListener("resize", updateMobileView);
  }, []);

  // function to toggle modal image
  const showImageInModal = () => {
    setShowImagePopup((state) => !state);
  };

  return (
    <Row className="mt-2 main-container-padding">
      <Col>
        <Row className="mt-1">
          <Col lg={11} md={11} sm={11}>
            <h2>Symptoms and Prevalence</h2>
            <hr className="title-border-bottom" />
          </Col>

          <Expand collapse={isVisible} setCollapse={setIsVisible} trackId="ats06280"/>
        </Row>

        <SlideDown transitionOnAppear={false}>
          {isVisible && (
            <Row className="symptoms-animate mt-3">
              <Col lg={6} md={6} sm={12}>
                <p>
                  As one of the leading causes of disability among young people
                  worldwide,<sup>1</sup> bipolar disorder is associated with
                  substantial psychosocial and economic burdens of illness.
                  <sup>3</sup> In the US, the 12-month and lifetime prevalences
                  bipolar I disorder were estimated to be 1.5% and 2.1%,
                  respectively.<sup>4</sup> Although mania is the hallmark
                  diagnostic symptom of a bipolar I diagnosis, manic symptoms
                  can be difficult to uncover as many patients do not report
                  them to their clinician.
                  <sup>2</sup> Additionally, patients with bipolar I disorder
                  report spending about 3 times more time in major or minor
                  depressive episodes than in manic or hypomanic episodes.
                  <sup>5</sup> Misdiagnosis of bipolar disorder can be very
                  common—most patients report that they are initially
                  misdiagnosed and since depressive episodes and depressive
                  symptoms are the predominant symptoms in bipolar I disorder,
                  it follows that MDD is the most common misdiagnosis.
                  <sup>2</sup>
                </p>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className={`text-center ${classes.positionRelative}`}
              >
                <h5 className={`${classes.symptomsHeading} mt-3 mb-3`}>
                  Misdiagnosis of Bipolar Disorder <sup>2</sup>
                </h5>

                <div
                  className={`card card-position popup-width ${classes.popupHeight}`}
                  style={{ display: showImagePopup ? "block" : "none" }}
                >
                  <div
                    className={`card-body custom-card-body ${classes.positionRelative}`}
                  >
                    <button
                      type="button"
                      className="close"
                      onClick={showImageInModal}
                    >
                      <span aria-hidden="true">×</span>
                      <span className="sr-only">Close</span>
                    </button>
                    <AnimationVideo
                      className={`${classes.popupAlignment}`}
                      width="105%"
                      id="group-150"
                      video={group150}
                    />
                  </div>
                </div>

                <div
                  className={`${classes.borderBox}`}
                  style={{ display: showImagePopup ? "none" : "block" }}
                >
                  {isMobileView ? (
                    <img
                      src={group148}
                      alt="Misdiagnosis of Bipolar Disorder"
                      width="100%"
                      onClick={showImageInModal}
                    />
                  ) : (
                    <AnimationVideo
                      className={`${classes.cursorPointer} mt-1 mb-5`}
                      width="90%"
                      id="symptoms-video"
                      showImageInModal={showImageInModal}
                      video={symptomsVideo}
                    />
                  )}
                </div>
              </Col>

              {/* <Paragraph rowClasses="container">
                <p className={`${classes.bipolarImgText}`}>
                  *In a study of primary care patients who had been prescribed
                  an antidepressant for depression, 21% actually screened
                  positive for bipolar disorder.<sup>6</sup>{" "}
                </p>
              </Paragraph> */}
            </Row>
          )}
        </SlideDown>
      </Col>
    </Row>
  );
};

export default Symptoms;
