import React from "react";
import { Route, Switch } from "react-router-dom";

import About from "../containers/About/About";
import BipolarDisorder from "../containers/BiploarDisorder/BiploarDisorder";
import Development from "../containers/Development/Development";
import Validation from "../containers/Validation/Validation";
import RMS from "../containers/RMS/RMS";
import ClinicianGuide from "../containers/ClinicianGuide/ClinicianGuide";
import PatientCaseQuizzes from "../containers/PatientCaseQuizzes/PatientCaseQuizzes";
import Multimedia from "../containers/Multimedia/Multimedia";
import QuickGuidesForProviders from "../containers/QuickGuidesForProviders/QuickGuidesForProviders";
import ClinicalArticleSummaries from "../containers/ClinicalArticleSummaries/ClinicalArticleSummaries";

/* Patient Case Quizzes */
import ScreeningPatientsWithBipolarDisorderInPractice from "../containers/PatientCaseQuizzes/ScreeningPatientsWithBipolarDisorderInPractice/ScreeningPatientsWithBipolarDisorderInPractice";

/* Multimedia */
import IntroducingTheRapidMoodScreener from "../containers/Multimedia/IntroducingTheRapidMoodScreener/IntroducingTheRapidMoodScreener";

/* Clinical Article Summaries */
import UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis from "../containers/ClinicalArticleSummaries/UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis/UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis";
import CharacteristicsOfPatientsWithEarlyBipolarDisorder from "../containers/ClinicalArticleSummaries/CharacteristicsOfPatientsWithEarlyBipolarDisorder/CharacteristicsOfPatientsWithEarlyBipolarDisorder";
/* Case Studies */
import CaseStudy1 from "../containers/CaseStudies/CaseStudy1";

import NotFound from "../components/NotFound/NotFound";
// const BipolarDisorder = React.lazy(() =>
//   import("../containers/BiploarDisorder/BiploarDisorder")
// );
// const Development = React.lazy(() =>
//   import("../containers/Development/Development")
// );
// const Validation = React.lazy(() =>
//   import("../containers/Validation/Validation")
// );
// const RMS = React.lazy(() => import("../containers/RMS/RMS"));
// const ClinicianGuide = React.lazy(() =>
//   import("../containers/ClinicianGuide/ClinicianGuide")
// );
// const NotFound = React.lazy(() => import("../components/NotFound/NotFound"));

const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={About} />
      <Route path="/about" exact component={About} />
      <Route path="/bipolar-disorder" exact component={BipolarDisorder} />
      <Route path="/development" exact component={Development} />
      <Route path="/validation" exact component={Validation} />
      <Route path="/rms" exact component={RMS} />
      <Route path="/clinician-guide" exact component={ClinicianGuide} />
      <Route path="/patient-case-quizzes" exact component={PatientCaseQuizzes} />
      <Route path="/multimedia" exact component={Multimedia} />
      <Route path="/quick-guides-for-providers" exact component={QuickGuidesForProviders} />
      <Route path="/clinical-article-summaries" exact component={ClinicalArticleSummaries} />

      {/* Patient Case Quizzes */}
      <Route path="/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice" exact component={ScreeningPatientsWithBipolarDisorderInPractice} />

      {/* Multimedia */}
      <Route path="/multimedia/introducing-the-rapid-mood-screener" exact component={IntroducingTheRapidMoodScreener} />

      {/* Clinical Article Summaries */}
      <Route path="/clinical-article-summaries/unrecognized-bipolar-disorder-in-patients-with-depression-managed-in-primary-care-a-systematic-review-and-meta-analysis" exact component={UnrecognizedBipolarDisorderInPatientsWithDepressionManagedInPrimaryCareASystematicReviewAndMetaAnalysis} />
      <Route path="/clinical-article-summaries/characteristics-of-patients-with-early-bipolar-disorder" exact component={CharacteristicsOfPatientsWithEarlyBipolarDisorder} />
      {/* Case Studies */}
      <Route path="/case-studies/case-study-1" exact component={CaseStudy1} />

      <Route render={() => <NotFound />} />
    </Switch>
  );
};

export default Router;
