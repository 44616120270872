import { useState, useEffect } from "react";

import NewBanner from "../../../components/NewBanner/NewBanner";
import BrightCove from "../../../components/BrightCove/BrightCove";
import classes from "./IntroducingTheRapidMoodScreener.module.css";
import References from "../../../components/References/References";

const IntroducingTheRapidMoodScreener = () => {
    return (
        <>
            <NewBanner title="Introducing the Rapid Mood Screener Tool" breadcrumbs={[{ title: "Clinical Experience", link: null }, { title: "Multimedia", link: '/multimedia' }, { title: "Introducing the Rapid Mood Screener Tool", link: null }]} />
            <BrightCove embedCode="https://bcove.video/4epQkrJ" />< br/>
            <p className={classes.articleCaption}>The Rapid Mood Screener (RMS) is a brief, self-administered screening tool for bipolar I disorder. In this video, Dr Roger McIntyre reviews the utility of the RMS in a busy clinical setting.</p>
            <p className={classes.articleCaption}>KOL: Roger McIntyre, MD, FRCPC</p>
            <p className={classes.smallText}>ABBV-US-00773-MC, V2.0 | Approved 01/2024 | AbbVie Medical Affairs</p>
        </>
    );
};

export default IntroducingTheRapidMoodScreener;
