import React from "react";

import classes from "./NewBanner.module.css";
import manyRectangles from "../../assets/images/2024/manyRectangles.png";

/**
 * RFC for Banner/Hero Image
 * @param {*} props
 * @returns
 */
const NewBanner = (props) => {
    return (
        <>
            <div className={classes.bannerContainer}>
                <img className={classes.manyRectangles} src={manyRectangles} alt="manyRectangles" />
                <div className={classes.bannerTitleContainer}>
                    <h1 className={`${classes.bannerTitle} ${props.center ? classes.centerTitle : ''}`}>{props.title}</h1>
                    <div className={classes.breadcrumbs}>
                        {props.breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={index}>
                                {crumb.link ? (
                                    <a href={crumb.link} className={classes.breadcrumbLink}>{crumb.title}</a>
                                ) : (
                                    <span className={classes.breadcrumb}>{crumb.title}</span>
                                )}
                                {index < props.breadcrumbs.length - 1 && (
                                    <span className={classes.breadcrumb}> &gt; </span>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewBanner;
