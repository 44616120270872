import React from "react";

import classes from "./ContentListing.module.css";

import { trackDigitalData } from "../../helpers/analytics";

const ContentListing = (props) => {
    return (
        <div className={classes.contentListingContainer}>
            {props.content.map((item, index) => (
                <div className={classes.contentListingItem} key={index}>
                    <a href={item.link} className={classes.contentLink} onClick={() => trackDigitalData('interaction', item.trackId)}>
                        <img src={item.image} alt={item.title} />
                        <div className={classes.contentListingItemContent}>
                            <p className={classes.contentListingItemType}>{item.type}</p>
                            <h3 className={classes.contentListingItemTitle}>{item.title}</h3>
                            <p className={classes.contentListingItemDescription}>{item.description}</p>
                        </div>
                    </a>
                </div>
            ))}
        </div>
    );
};

export default ContentListing;
