import React from "react";

import classes from "./Featured.module.css";

const Featured = (props) => {
    return (
        <>
            <div className={classes.featuredContainer}>
                <h2 className={classes.featuredHeading}>Featured</h2>
                <div className={classes.featuredCardsContainer}>
                    <div className={classes.featuredImageContainer}>
                        <img src={props.image} alt={props.title} />
                    </div>
                    <div className={classes.featuredContentContainer}>
                        <p className={classes.featuredType}>{props.type}</p>
                        <h3 className={classes.featuredTitle}>{props.title}</h3>
                        <p 
                            className={classes.featuredDescription}
                            dangerouslySetInnerHTML={{ __html: props.description }}
                        />
                        <a href={props.link} className={classes.featuredButton} target={props.target}>{props.buttonText}</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Featured;
