import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import NewBanner from "../../components/NewBanner/NewBanner";
import Paragraph from "../../components/Paragraph/Paragraph";
import References from "../../components/References/References";
import PhaseCard from "../../components/PhaseCard/PhaseCard";

import banner from "../../assets/images/development_banner.jpg";
import mobileBanner from "../../assets/images/development_mobile.jpg";
import phase1 from "../../assets/images/phase1.png";
import phase2 from "../../assets/images/phase2.png";

import classes from "./Development.module.css";

import phase3 from "../../assets/images/phase3.png";
import Details from "./Details/Details";
import PredicitveTable from "./PredicitveTable/PredicitveTable";
import Characteristics from "./Characteristics/Characteristics";

const Development = () => {
  const [showPhase1Popup, setShowPhase1Popup] = useState(false);
  const [showPhase2Popup, setShowPhase2Popup] = useState(false);
  const [showPhase3Popup, setShowPhase3Popup] = useState(false);

  useEffect(() => {
    document.title = "RMS | Development and Validation";
  });

  const showPhaseHandler = (phaseNumber) => {
    // If 0 is passed, close all cards
    if (phaseNumber === 0) {
      setShowPhase1Popup(false);
      setShowPhase2Popup(false);
      setShowPhase3Popup(false);
      return;
    }

    // Otherwise toggle the selected phase
    switch (phaseNumber) {
      case 1:
        setShowPhase1Popup(prev => !prev);
        setShowPhase2Popup(false);
        setShowPhase3Popup(false);
        break;
      case 2:
        setShowPhase1Popup(false);
        setShowPhase2Popup(prev => !prev);
        setShowPhase3Popup(false);
        break;
      case 3:
        setShowPhase1Popup(false);
        setShowPhase2Popup(false);
        setShowPhase3Popup(prev => !prev);
        break;
    }
  };

  return (
    <Row>
      <Col>
        <NewBanner title="Development and Validation" breadcrumbs={[{}]} />

        <Row className="margin-to-banner main-container-padding">
          <Col>
            <Paragraph>
              <p className="mt-2">
                The rapid mood screener was developed and validated in a 3-step process with the
                goal of creating a pragmatic and accurate tool that
                screens for bipolar I disorder.<sup>1</sup> This
                clinician-administered tool was designed to screen for bipolar
                depression features and manic symptoms using the fewest number
                items possible while maintaining strong test characteristics.
              </p>
            </Paragraph>
          </Col>
        </Row>

        <Row className="main-container-padding mb-5">
          <Col lg={5} md={5} sm={12} className="text-center position-relative">
            {showPhase1Popup && (
              <PhaseCard
                phaseNumber={1}
                clicked={showPhaseHandler.bind(this, 1)}
              >
                <h6 className="phase-heading">
                  Concept and Item Development
                </h6>
                <ul>
                  <li>
                    Targeted literature search
                  </li>
                  <li>
                    Two Delphi consensus panels with multidisciplinary group of clinical experts
                  </li>
                  <li>
                    Ten draft screener items developed for qualitative and quantitative testing
                  </li>
                </ul>
              </PhaseCard>
            )}

            <div>
              <img
                className="phase-image"
                src={phase1}
                alt="phase1"
                onClick={showPhaseHandler.bind(this, 1)}
              />
              {/* {!showPhase1Popup && ( */}
              <span
                className={`${classes.phase1Circle1}`}
                onClick={showPhaseHandler.bind(this, 1)}
              >
                <span className={`${classes.phaseText1}`}>Phase</span> <br />
                <span className={`${classes.phaseNumber1}`}>1</span>
              </span>
              {/* )} */}
              <span className={`${classes.phase1circle2}`}></span>
              <span className={`${classes.phase1circle3}`}></span>
            </div>
          </Col>
          <Col lg={7} md={7} sm={12} className="align-content">
            <p className="mt-1 mb-3">
              RMS development began with a literature search conducted
              to identify concepts that could differentiate bipolar I disorder
              from MDD <b>(Phase 1)</b>.
            </p>
          </Col>
        </Row>

        <hr className={classes.horizontalLine} />

        <Row className="main-container-padding mt-4">
          <Col lg={5} md={5} sm={12} className="text-center position-relative">
            {showPhase2Popup && (
              <PhaseCard
                phaseNumber={2}
                clicked={showPhaseHandler.bind(this, 2)}
              >
                <h6 className="phase-heading">
                  Qualitative Interviews with Patients{" "}
                  <br className="custom-break" />
                </h6>
                <ul>
                  <li>
                    Wording of items refined based on cognitive debriefing
                    interviews with individuals with MDD or bipolar I disorder,
                    ensuring item understanding and interpretation (n = 12)
                  </li>
                </ul>
              </PhaseCard>
            )}
            <div>
              <img
                className="phase-image width-phase2"
                src={phase2}
                alt="phase2"
                onClick={showPhaseHandler.bind(this, 2)}
              />
              <span className={`${classes.phase2circle2}`}></span>
              <span
                className={`${classes.phase2Circle1}`}
                onClick={showPhaseHandler.bind(this, 2)}
              >
                <span className={`${classes.phaseText2}`}>Phase</span> <br />
                <span className={`${classes.phaseNumber2}`}>2</span>
              </span>
              <span className={`${classes.phase2circle3}`}></span>
              <span className={`${classes.phase2circle4}`}></span>
            </div>
          </Col>
          <Col lg={7} md={7} sm={12}>
            <p className="mb-3 mt-3">
              A multidisciplinary team of mental health and psychometric experts
              identified the concepts that were the most discriminating for
              bipolar I disorder. Using these concepts and manic symptom
              indicators, the development team drafted an initial group of items
              that were anticipated to differentiate patients with bipolar I
              disorder from those with MDD. These draft items were evaluated in
              cognitive debriefing interviews with adults who had a diagnosis of
              bipolar I disorder or MDD <b>(Phase 2)</b>.
            </p>
          </Col>
        </Row>

        <Paragraph rowClasses="mt-3 main-container-padding">
          <p>
            Based on insights from the interview participants, modifications
            were made to the draft items to improve clarity, refine
            patient-friendly wording, and support content validity. Ten draft
            screener items were retained for quantitative evaluation and
            validation.
          </p>
        </Paragraph>

        {/*
        <Paragraph rowClasses="mt-2 main-container-padding">
          <p className="phase-info">
            *BP-I, bipolar I disorder; MDD, major depressive disorder.
          </p>
        </Paragraph>
        */}

        <Row className="main-container-padding mt-4">
          <Col lg={5} md={5} sm={12} className="text-center position-relative">
            {showPhase3Popup && (
              <PhaseCard
                phaseNumber={3}
                clicked={() => showPhaseHandler(3)}
              >
                <h6 className="phase-heading">
                  Quantitative Evaluation (Validation)
                </h6>
                <ul>
                  <li>Observational study conducted with individuals with MDD or
                    bipolar I disorder (n = 139)
                  </li>
                  <li>
                    Draft 10-item bipolar I disorder screening tool, CES-D, and MDQ administered
                  </li>
                  <li>
                    Combination of item sets were simultaneously evaluated by the development team
                    to optimize scoring and test characteristics in as few items as necessary
                  </li>
                </ul>
              </PhaseCard>
            )}

            <div>
              <img
                className="phase-image pl-4 width-phase3"
                src={phase3}
                alt="phase3"
                onClick={() => showPhaseHandler(3)}
              />
              {!showPhase3Popup && (
                <span
                  className={`${classes.phase3Circle1}`}
                  onClick={() => showPhaseHandler(3)}
                >
                  <span className={`${classes.phaseText3}`}>Phase</span> <br />
                  <span className={`${classes.phaseNumber3}`}>3</span>
                </span>
              )}
              <span className={`${classes.phase3circle2}`}></span>
              <span className={`${classes.phase3circle3}`}></span>
            </div>
          </Col>
          <Col lg={7} md={7} sm={12}>
            <Details />
          </Col>
        </Row>

        <Paragraph rowClasses="mt-2 main-container-padding">
          <p className="mb-3">
            Based on the responses to the draft screening tool, the development
            team selected the combination of items that would collectively
            provide the best balance of specificity and sensitivity while using
            the smallest possible number of items. Six final RMS items, 3 that
            screen for depression symptoms that are characteristic of bipolar I
            disorder and 3 that screen for manic symptoms, were chosen. YES
            responses on 4 or more RMS items had the highest estimated accuracy
            for identifying participants with bipolar I disorder. Three or more
            YES responses also signaled a higher likelihood for bipolar I
            disorder than for MDD. When a respondent has a positive screen, a
            comprehensive follow-up evaluation by a healthcare provider is
            needed to confirm a diagnosis of bipolar I disorder.
          </p>
        </Paragraph>

        <PredicitveTable />

        <Characteristics>
          <Paragraph rowClasses="mr-1 mt-0">
            <div className="d-flex">
              <p className={`${classes.tableSubScript}`}>
                <sup>a</sup>&nbsp;
              </p>
              <p className={`${classes.tableText}`}>
                To screen positive for possible bipolar disorder on the RMS,
                "Yes" is endorsed for 4 or more items.
              </p>
            </div>
            <div className={`d-flex ${classes.tableDetailsMarginTop}`}>
              <p className={`${classes.tableSubScript}`}>
                <sup>b</sup>&nbsp;
              </p>
              <p className={`${classes.tableText}`}>
                To screen positive for possible bipolar disorder on the MDQ, 3
                criteria must be met: "Yes" to 7 or more of the 13 items in
                Question 1 AND "Yes" to Question 2 AND "Moderate Problem" of
                "Serious Problem" Question 3.
              </p>
            </div>
          </Paragraph>
        </Characteristics>

        <References>
          <li>
            McIntyre RS, Patel MD, Masand PS, et al. The Rapid Mood Screener
            (RMS): A novel and pragmatic screener for bipolar I disorder.{" "}
            <i>Curr Med Res Opin</i>. 2021;37(1):135<span>&ndash;</span>144.
          </li>
          <li>
            Hirschfeld RM, Williams JB, Spitzer RL, et al. Development and
            validation of a screening instrument for bipolar spectrum disorder:
            the Mood Disorder Questionnaire. <i>Am J Psychiatry</i>.
            2000;157(11):1873<span>&ndash;</span>1875.
          </li>
        </References>
      </Col >
    </Row >
  );
};

export default Development;
