import { useState, useEffect } from "react";

import NewBanner from "../../components/NewBanner/NewBanner";
import Featured from "../../components/Featured/Featured";
import ContentListing from "../../components/ContentListing/ContentListing";

import featuredImage from "../../assets/images/2024/clinicalArticleSummaries/featured.png";

import contentListingImage0 from "../../assets/images/2024/clinicalArticleSummaries/listingImage0.jpg";

import { trackDigitalData } from "../../helpers/analytics";

const ClinicalArticleSummaries = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06092');
    }, []);
    return (
        <>
            <NewBanner title="Clinical Article Summaries" breadcrumbs={[{title: "Bipolar I Disorder", link: null}, {title: "Clinical Article Summaries", link: null}]} />
            <Featured
                image={featuredImage}
                title="Unrecognized Bipolar Disorder in Patients With Depression Managed in Primary Care: A Systematic Review and Meta-Analysis"
                type="Clinical Article Summary"
                description="Daveney et al explore the characteristics of patients with mixed symptoms, as compared to those without mixed symptoms, in both bipolar disorder and major depressive disorder."
                link="/clinical-article-summaries/unrecognized-bipolar-disorder-in-patients-with-depression-managed-in-primary-care-a-systematic-review-and-meta-analysis"
                buttonText="READ MORE"
                trackId="ats06282"
            />
            <ContentListing content={
                [
                    {
                        image: contentListingImage0,
                        title: "Characteristics of Patients With Early Bipolar Disorder",
                        type: "Clinical Article Summary",
                        description: "O Donovan et al identify characteristics of patients who experience depression before bipolar disorder.",
                        link: "/clinical-article-summaries/characteristics-of-patients-with-early-bipolar-disorder",
                        trackId: "ats06283"
                    },
                ]
            }/>
        </>
    );
};

export default ClinicalArticleSummaries;
