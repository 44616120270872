import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SlideDown } from "react-slidedown";

import Expand from "../../../components/Expand/Expand";
import Paragraph from "../../../components/Paragraph/Paragraph";

const Scoring = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <Row className="mt-3 main-container-padding">
      <Col>
        <Row>
          <Col md={11} sm={11}>
            <h2>Scoring and Validity*</h2>
            <hr className="title-border-bottom" />
          </Col>
          <Expand collapse={isVisible} setCollapse={setIsVisible} trackId="ats06308" />
        </Row>

        <SlideDown transitionOnAppear={false}>
          {isVisible && (
            <Row className="scoring-animate">
              <Col>
                <Paragraph>
                  <p>
                    “YES” responses to 4 or more of the 6 items is considered a
                    positive screen providing high confidence for bipolar I
                    disorder, with an estimated 88% sensitivity, 80%
                    specificity, and 84% accuracy.
                  </p>
                </Paragraph>

                <Paragraph>
                  <ul>
                    <li>
                      Sensitivity = percentage of patients who have bipolar I
                      disorder that are correctly identified as positive
                    </li>
                    <li>
                      Specificity = percentage of patients who do not have
                      bipolar I disorder and who are correctly identified as
                      negative
                    </li>
                    <li>
                      Accuracy = percentage of patients correctly predicted as
                      bipolar I disorder or not
                    </li>
                  </ul>
                </Paragraph>
              </Col>
            </Row>
          )}
        </SlideDown>
      </Col>
    </Row>
  );
};

export default Scoring;
