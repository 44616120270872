import { useContext } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import { GlobalContext } from "../../../state/GlobalState";

import { isIos } from "../../../helpers/isIos";

import classes from "./Speciality.module.css";

import { trackDigitalData } from "../../../helpers/analytics";

const Speciality = (props) => {
  const { state, dispatchActions } = useContext(GlobalContext);

  let selectClass = isIos() ? classes.iosSelect : "";

  const onChangeHandler = (e) => {
    if (e.target.value === "") return;

    dispatchActions.setSpeciality(e.target.value);

    switch (e.target.value) {
      case "Physician (Psychiatric)":
        trackDigitalData('interaction', 'ats06049', true);
        break;
      case "Nurse Practitioner (Psychiatric)":
        trackDigitalData('interaction', 'ats06053', true);
        break;
      case "Physician Assistant (Psychiatric)":
        trackDigitalData('interaction', 'ats06054', true);
        break;
      case "Physician (Family)":
        trackDigitalData('interaction', 'ats06056', true);
        break;
      case "Nurse Practitioner (Family)":
        trackDigitalData('interaction', 'ats06058', true);
        break;
      case "Physician Assistant (Family)":
        trackDigitalData('interaction', 'ats06051', true);
        break;
      case "Pharmacist":
        trackDigitalData('interaction', 'ats06052', true);
        break;
      case "Physician (Other)":
        trackDigitalData('interaction', 'ats06055', true);
        break;
      case "Nurse Practitioner (Other)":
        trackDigitalData('interaction', 'ats06050', true);
        break;
      case "Physician Assistant (Other)":
        trackDigitalData('interaction', 'ats06057', true);
        break;
    }
  };

  return (
    <>
      <Row className="button-container mt-2 mb-2">
        <Col lg={6} md={6} sm={12} className="select-container">
          <select
            className={`form-select ${classes.hcpDropdown} ${selectClass}`}
            onChange={onChangeHandler}
            required
          >
            <option className={classes.optionBold} value="">
              Choose your specialty
            </option>
            <option
              className={classes.optionBold}
              value="Physician (Psychiatric)"
              onChange={() => trackDigitalData('interaction', 'ats06049', true)}
            >
              Physician (Psychiatric)
            </option>
            <option
              className={classes.optionBold}
              value="Nurse Practitioner (Psychiatric)"
            >
              Nurse Practitioner (Psychiatric)
            </option>
            <option
              className={classes.optionBold}
              value="Physician Assistant (Psychiatric)"
            >
              Physician Assistant (Psychiatric)
            </option>
            <option className={classes.optionBold} value="Physician (Family)">
              Physician (Family)
            </option>
            <option
              className={classes.optionBold}
              value="Nurse Practitioner (Family)"
            >
              Nurse Practitioner (Family)
            </option>
            <option
              className={classes.optionBold}
              value="Physician Assistant (Family)"
            >
              Physician Assistant (Family)
            </option>
            <option className={classes.optionBold} value="Pharmacist">
              Pharmacist
            </option>
            <option className={classes.optionBold} value="Physician (Other)">
              Physician (Other)
            </option>
            <option
              className={classes.optionBold}
              value="Nurse Practitioner (Other)"
            >
              Nurse Practitioner (Other)
            </option>
            <option
              className={classes.optionBold}
              value="Physician Assistant (Other)"
            >
              Physician Assistant (Other)
            </option>
          </select>
        </Col>
        <Col lg={6} md={6} sm={12}></Col>
      </Row>
      <Row>
        <Col className="text-center">
          {state.loading && <Spinner animation="border" variant="primary" />}
          {state.error && (
            <h6 className={`${classes.error}`}>{state.errorMessage}</h6>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Speciality;
