import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

import pdf from "../../assets/pdfs/Rapid_Mood_Screener_Questionaire.pdf";

import classes from "./Download.module.css";

import { isIos } from "../../helpers/isIos";
import { trackDigitalData } from "../../helpers/analytics";

/**
 * RFC for Downloading pdf
 * @param {pdfName: 'name.pdf'} props
 * @returns <JSX />
 */
const Download = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadPdf = () => {
    trackDigitalData('interaction', props.trackId);
    setIsDownloading(true);
    let anchor = document.createElement("a");
    anchor.setAttribute("target", "_blank");

    anchor.setAttribute("href", pdf);
    if (isIos()) {
      anchor.setAttribute("target", "_blank");
    } else {
      anchor.setAttribute("download", props.pdfName);
    }

    anchor.click();
    setTimeout(() => setIsDownloading(false), 2000);
  };

  return (
    <Row className="mt-2 mb-3">
      <Col className={classes.buttonPlacement}>
        <Button
          id="download-pdf"
          variant=""
          className={`btn active ${classes.downloadPdf}`}
          data-toggle="tooltip"
          data-placement="bottom"
          title="Allows you to download blank RMS questionnaire and Clinician Guide"
          onClick={downloadPdf}
          disabled={isDownloading ? "disabled" : false}
        >
          {isDownloading ? "Downloading PDF" : "Download PDF"}
        </Button>
      </Col>
    </Row>
  );
};

export default Download;
