import { NavLink } from "react-router-dom";

import logo from "../../../assets/images/logo.svg";

import classes from "./Logo.module.css";

import { trackDigitalData } from "../../../helpers/analytics";

const Logo = (props) => (
  <NavLink to="/" className="logo" onClick={() => {
    props.clicked();
    trackDigitalData('interaction', 'ats06060', true);
  }}>
    <img className={classes.desktopLogo} src={logo} alt="RMS Logo" />
    <img className={classes.mobileLogo} src={logo} alt="RMS Logo" />

  </NavLink>
);

export default Logo;
